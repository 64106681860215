import Vue from 'vue'
import storeLocal from 'storejs'
import axios from 'axios'
import router from '../../router/router'
import { message } from 'ant-design-vue'
import { RefreshToken } from '@a/js/api/public'

import apiObj from "@a/js/api.js";

Vue.prototype.$storeLocal = storeLocal

// token验证开关
const verityToken = true

/* 是否有请求正在刷新token */
window.isRefreshing = false
/* 被挂起的请求数组 */
let refreshSubscribers = []

/* push所有请求到数组中 */
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

/* 刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据） */
function onRrefreshed(token) {
  refreshSubscribers.map((cb) => cb(token))
}

function computedTime() {
  let r = Vue.prototype.$storeLocal.get('expire')
  if (!r) return false
  let currentTime = Date.parse(new Date()) / 1000
  let expiresTime = r
  // 5分钟后即将过期,true则不需要刷新
  return expiresTime - currentTime > 0 && expiresTime - currentTime <= 5 * 60
}
// create an axios instance
const service = axios.create({
  baseURL: apiObj.VUE_APP_BASE_API,
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (
      config.url === '/b/base/v1/action/login' ||
      config.url === '/g/v1/token' ||
      !verityToken
    ) {
      return config
    } else {
      if (computedTime()) {
        if (!window.isRefreshing) {
          window.isRefreshing = true
          let data = {
            account_uid: Vue.prototype.$storeLocal.get('account').uid,
            token: Vue.prototype.$storeLocal.get('token'),
          }
          RefreshToken(data)
            .then((res) => {
              window.isRefreshing = false
              Vue.prototype.$storeLocal.set(
                'expire',
                Date.parse(new Date()) / 1000 + res.data.expire
              )
              Vue.prototype.$storeLocal.set('token', res.data.token)
              onRrefreshed(res.data.token)
              /* 执行onRefreshed函数后清空数组中保存的请求 */
              refreshSubscribers = []
            })
            .catch(() => {
              // setTimeout(() => {
              //   window.location.href =
              //     apiObj.VUE_APP_BASE_SERVE + 'base/login'
              // }, 2000)
            })
        }
        /* 把请求(token)=>{....}都push到一个数组中 */
        let retry = new Promise((resolve) => {
          /* (token) => {...}这个函数就是回调函数 */
          subscribeTokenRefresh((token) => {
            config.headers.token = token
            /* 将请求挂起 */
            resolve(config)
          })
        })
        return retry
      } else {
        config.headers.token = Vue.prototype.$storeLocal.get('token')
        return config
      }
    }
  },
  (error) => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 'success') {
      // 判断是否为未授权
      if (res.code === 10022 || res.code === 10021) {
        message.error(res.msg).then(() => {
          let params = router.history.current.params;

          window.location.href =
            apiObj.VUE_APP_BASE_SERVE +
            'base/project/apps' +
            '/' +
            params.accountid +
            '/' +
            params.projectid;
        })
      } else if (res.code === 10030) {
        //判断token是否过期或token验证失败
        message.error(res.msg).then(() => {
          // window.location.href = apiObj.VUE_APP_BASE_SERVE + 'base/login'
        })
      } else {
        message.error(res.msg)
      }

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    const errorObj = {
      400: '请求错误 - 400',
      401: '未授权，请重新登录 - 401',
      403: '拒绝访问 - 403',
      404: '请求出错 - 404',
      408: '请求超时 - 408',
      500: '服务端响应 - 500',
      501: '服务未实现 - 501',
      502: '网络错误 - 502',
      503: '服务不可用 - 503',
      504: '网络超时 - 504',
      505: 'HTTP版本不受支持 - 505',
    }

    if (error && error.response) {
      if (errorObj[error.response.status]) {
        message.error(errorObj[error.response.status])
      } else {
        message.error(`连接出错 - ${error.response.status}!`)
      }
    } else {
      message.error('连接服务器失败！')
    }

    return Promise.reject(error)
  }
)

export default service
