<!-- 产品首页 -->
<template>
  <div class='products_home_warp'>
    <div class="pro_body">
      <div
        class="pro_header_wrap"
        data-aos="fade-in"
      >
        <div class="pro_header_title">产品中心</div>
        <div class="pro_header_desc">适合各类会议、场景，最全面的产品集合</div>
        <div class="pro_header_search_warp">
          <a-input-search
            placeholder="请输入产品名称"
            size="large"
            class="pro_header_search"
          >
            <a-button
              @click="onSearch"
              icon="search"
              slot="enterButton"
              type="primary"
            >
              搜索
            </a-button>
          </a-input-search>
        </div>
      </div>
      <div
        class="pro_content_wrap"
        data-aos="fade-up"
      >
        <div class="pro_nav_wrap">
          <div
            class="pro_nav_item"
            v-for="item of navlist"
            :key="item.value"
          >
            <span
              class="pro_nav_text"
              :class="item.active ? 'active_nav' : ''"
            >{{item.label}}</span>
          </div>
        </div>
        <div class="pro_list_wrap">
          <div
            class="pro_list_item"
            v-for="pro of prolist"
            :key="pro.id"
            data-aos="zoom-in"
            @click="handleProItem"
          >

            <div
              class="pro_item_img_wrap"
              v-lazy:background-image="pro.img"
            >
              <!-- <img v-lazy="pro.img"> -->
            </div>
            <div class="pro_item_info_wrap">
              <div class="pro_item_name">{{pro.name}}</div>
              <div class="pro_item_desc">{{pro.desc}}</div>
              <div class="pro_item_footer">
                <div class="pro_item_view_wrap">
                  <a-icon
                    class="pro_item_view_icon"
                    type="eye"
                  />
                  <span class="pro_item_view_num">{{pro.views}}</span>
                </div>
                <div class="pro_item_detail_text">查看详情</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Input,
  Icon
} from 'ant-design-vue'
Vue.use(Input)
Vue.use(Icon)
export default {
  name: 'ProductsHome',
  data() {
    return {
      navlist: [
        {
          label: '新品',
          value: 'new',
          active: false
        },
        {
          label: '全部',
          value: 'all',
          active: true
        },
        {
          label: '软件',
          value: 'software',
          active: false
        },
        {
          label: '硬件',
          value: 'hardware',
          active: false
        },
        {
          label: '配套',
          value: 'match',
          active: false
        },
        {
          label: '服务',
          value: 'service',
          active: false
        },
        {
          label: '定制',
          value: 'custom',
          active: false
        },
        {
          label: '私有云',
          value: 'privcloud',
          active: false
        },
      ],
      prolist: [
        {
          id: 'pro01',
          img: require("@a/img/products/scancheck.png"),
          name: '扫码签到',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 1223
        },
        {
          id: 'pro02',
          img: require("@a/img/products/idcheck.png"),
          name: '身份证签到',
          desc: '前期收集（注册、导入）参会嘉宾身份证号码，嘉宾到签到处出示身份证，扫描身份证，完成签到，实时统计签到数据',
          views: 1121
        },
        {
          id: 'pro03',
          img: require("@a/img/products/registercheck.png"),
          name: '注册报名',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 1031
        },
        {
          id: 'pro04',
          img: require("@a/img/products/scancheck.png"),
          name: '注册报名',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 997
        },
        {
          id: 'pro05',
          img: require("@a/img/products/scancheck.png"),
          name: '注册报名',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 997
        },
        {
          id: 'pro06',
          img: require("@a/img/products/scancheck.png"),
          name: '注册报名',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 997
        },
        {
          id: 'pro07',
          img: require("@a/img/products/scancheck.png"),
          name: '注册报名',
          desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
          views: 997
        },

      ]
    };
  },

  components: {},

  methods: {
    // 点击搜索
    onSearch() {
      console.log('search')
    },
    // 点击查看详情
    handleProItem() {
      this.$router.push({
        name: "ProductsIntro"
      })
    }
  },

  mounted() {
    // console.log(this.$AOS)
  },
}
</script>
<style lang='less' scoped>
.products_home_warp {
  width: 100%;
  background: #fff;
  .pro_body {
    width: 1200px;
    margin: 0 auto;
    overflow-y: hidden;
    .pro_header_wrap {
      padding: 135px 0 70px;
      .pro_header_title {
        font-size: 44px;
        line-height: 66px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #000;
        font-weight: bold;
      }
      .pro_header_desc {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.1em;
        text-align: center;
        color: #000;
      }
      .pro_header_search_warp {
        margin-top: 30px;
        text-align: center;
        .pro_header_search {
          width: 360px;
        }
      }
    }
    .pro_content_wrap {
      padding: 0 100px;
      // 分类
      .pro_nav_wrap {
        height: 70px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        padding: 0 50px;
        .pro_nav_item {
          flex: 1;
          margin: 0 15px;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s ease;
          user-select: none;
          &:hover {
            transform: translateY(-2px);
          }
          .pro_nav_text {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1em;
            color: #000;
          }
          .active_nav {
            color: #06f;
            font-weight: bold;
          }
        }
      }
      // 列表
      .pro_list_wrap {
        padding-bottom: 100px;
        padding-top: 20px;
        .pro_list_item {
          font-size: 0;
          margin-top: 20px;
          margin-bottom: 20px;
          width: 306px;
          display: inline-block;
          border-radius: 2px;
          box-sizing: border-box;
          // border: 1px solid #ddd;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          background: #fff;
          cursor: pointer;
          transition: all 0.3s ease;
          overflow: hidden;
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0px 2px 8px rgba(0, 102, 225, 0.1);
          }
          &:nth-child(3n-1) {
            margin-left: 40px;
            margin-right: 40px;
          }
          .pro_item_img_wrap {
            height: 240px;
            background-size: cover;
            background-repeat: no-repeat;
            // display: flex;
            // align-items: center;
            // img {
            //   width: 100%;
            //   margin: 0 auto;
            // }
          }
          .pro_item_info_wrap {
            padding: 20px;
            user-select: none;
            .pro_item_name {
              font-size: 18px;
              font-weight: bold;
              line-height: 26px;
              color: #000;
              margin-bottom: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              white-space: nowrap;
            }
            .pro_item_desc {
              font-size: 14px;
              line-height: 20px;
              color: #999;
              margin-bottom: 30px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .pro_item_footer {
              display: flex;
              .pro_item_view_wrap {
                flex: 1;
                display: flex;
                align-items: center;
                .pro_item_view_icon,
                .pro_item_view_num {
                  font-size: 14px;
                  color: #999;
                }
                .pro_item_view_num {
                  margin-left: 5px;
                }
              }
              .pro_item_detail_text {
                font-size: 12px;
                line-height: 18px;
                color: #06f;
              }
            }
          }
        }
      }
    }
  }
}
</style>