<!-- PDA 签到 -->
<template>
  <AppCard
    :animate="'fade-left'"
    :introImg="introImg"
    :title="'移动签到 - PDA 版'"
    :introInfo="'适用于用餐、接机、分会场、室外、游轮等签到场景。PDA 配备的工业级二维码扫描头，将更敏捷地完成签到。扫描下方二维码下载 app。'"
  >
    <template #download>
      <div class="pda_dowmload_wrap">
        <vueQr
          class="code_link"
          :text="link"
          :size="120"
          :margin="0"
        />
        <div class="pda_download_btn_wrap">
          <DownloadBtn
            :icon="'android'"
            :text="'Android 下载'"
            @listenHandleClick="hanldeWannaDownload"
          />
        </div>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from './appcard'
import DownloadBtn from './downloadBtn'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
import vueQr from 'vue-qr'
export default {
  name: 'PdaCheck',
  data() {
    return {
      introImg: apiObj.VUE_APP_BASE_CDN + Assets.pda,
      orglink: '/apps/check_pda/pro/check_pda.apk',
      link: ''
    };
  },

  components: {
    AppCard,
    DownloadBtn,
    vueQr
  },

  methods: {
    // 初始化链接
    initDownloadLink() {
      let str = apiObj.VUE_APP_BASE_CDN + this.orglink + '?v=' + new Date().getTime()
      this.link = str
    },
    // 点击下载
    hanldeWannaDownload() {
      window.location.href = this.link
    }
  },

  mounted() {
    this.initDownloadLink()
  },
}
</script>
<style lang='less' scoped>
.pda_dowmload_wrap {
  height: 120px;
  display: flex;
  .code_link {
    margin-right: 75px;
  }
  .pda_download_btn_wrap {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
</style>