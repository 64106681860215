<!-- 产品详情 -->
<template>
  <div class='pro_intro_wrap'>
    <div class="pro_intro_body">
      <div class="intro_content">
        <!-- 返回按钮 -->
        <div class="back_wrap">
          <div class="back_btn_wrap">
            <a-icon
              class="back_icon"
              type="left"
            />
            <span class="back_text">返回产品中心</span>
          </div>
        </div>
        <!-- 详情简介 -->
        <div class="intro_hedar_wrap">
          <div class="intro_header_img_wrap">
            <img v-lazy="info.img">
          </div>
          <div class="intro_header_info_wrap">
            <div class="intro_name">{{info.name}}</div>
            <div class="intro_desc">{{info.desc}}</div>
            <div class="intro_footer_wrap">
              <div class="intro_tags_wrap">
                <a-tag
                  color="#f50"
                  v-for="(tag,index) of info.tags"
                  :key="index"
                >
                  {{tag}}
                </a-tag>
              </div>
              <div class="intro_view_wrap">
                <a-icon
                  class="intro_view_icon"
                  type="eye"
                />
                <span class="intro_view_num">{{info.views}}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 详细信息 -->
        <div class="intro_info_wrap"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Icon,
  Tag
} from 'ant-design-vue'
Vue.use(Icon)
Vue.use(Tag)
export default {
  name: 'ProDetail',
  data() {
    return {
      info: {
        img: require("@a/img/products/scancheck.png"),
        name: '扫码签到',
        desc: '向参会嘉宾发放二维码电子票，嘉宾到场后出示二维码电子票，由工作人员扫码后，完成签到，实时统计签到数据。',
        views: 1223,
        tags: ['软件'],
        infos: '详细信息 '
      },
    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.pro_intro_wrap {
  width: 100%;
  background: #fff;
  .pro_intro_body {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 180px;
    .intro_content {
      width: 100%;
      overflow: hidden;
      // 返回按钮
      .back_wrap {
        margin-top: 34px;
        .back_btn_wrap {
          display: inline-block;
          user-select: none;
          cursor: pointer;
          &:hover {
            .back_icon,
            .back_text {
              color: #06f;
            }
          }
          .back_icon {
            font-size: 16px;
            color: #999;
          }
          .back_text {
            font-size: 16px;
            line-height: 24px;
            color: #999;
            margin-left: 5px;
          }
        }
      }
      // 详情简介
      .intro_hedar_wrap {
        margin-top: 34px;
        padding-bottom: 50px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        display: flex;
        .intro_header_img_wrap {
          width: 350px;
          height: 240px;
          margin-right: 80px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 2px;
          }
        }
        .intro_header_info_wrap {
          flex: 1;
          min-height: 240px;
          display: flex;
          flex-direction: column;
          .intro_name {
            font-size: 26px;
            line-height: 40px;
            letter-spacing: 0.05em;
            color: #000;
            font-weight: bold;
            margin-bottom: 30px;
          }
          .intro_desc {
            flex: 1;
            font-size: 16px;
            line-height: 24px;
            color: #777;
            text-align: justify;
            max-height: 120px;
            margin-bottom: 28px;
            overflow-y: auto;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.1);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          .intro_footer_wrap {
            display: flex;
            .intro_tags_wrap {
              flex: 1;
            }
            .intro_view_wrap {
              display: flex;
              align-items: center;
              .intro_view_icon {
                font-size: 16px;
                color: #999;
              }
              .intro_view_num {
                font-size: 16px;
                color: #999;
                margin-left: 5px;
                // line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>