const Assets = {
  logo_blue: '/system/image/official/logo/logo_blue.png',
  logo_white: '/system/image/official/logo/logo_white.png',
  ai_smart_check: '/system/image/official/apps/ai_smart_check.png',
  desktop_check: '/system/image/official/apps/desktop_check.png',
  minprogram: '/system/image/official/apps/minprogram.png',
  pda: '/system/image/official/apps/pda.png',
  qr_miniprogram: '/system/image/official/apps/qr_miniprogram.png',
  focus_01: '/system/image/official/focus/focus_01_20211130update.png',
  focus_01_text: '/system/image/official/focus/focus_01_text_20211130update.png',
  attendee: '/system/image/official/focus/attendee.png',
  mice: '/system/image/official/focus/mice.png',
  session: '/system/image/official/focus/session.png',
  sponsor: '/system/image/official/focus/sponsor.png',
  register_bg: '/system/image/official/index/register_bg_20211130update.png',
  hot: '/system/image/official/index/hot.png',
  partners: '/system/image/official/partners/partners.png',
  academic: '/system/image/official/plans/academic.png',
  planscontract: '/system/image/official/plans/contract.png',
  online: '/system/image/official/plans/online.png',
  train: '/system/image/official/plans/train.png',
  plansvip: '/system/image/official/plans/vip.png',
  booking: '/system/image/official/products/booking.png',
  check: '/system/image/official/products/check.png',
  msgcontract: '/system/image/official/products/contract.png',
  crm: '/system/image/official/products/crm.png',
  digital_property: '/system/image/official/products/digital_property.png',
  diy_logo: '/system/image/official/products/diy_logo.png',
  group_permission: '/system/image/official/products/group_permission.png',
  hudong: '/system/image/official/products/hudong.png',
  live: '/system/image/official/products/live.png',
  message: '/system/image/official/products/message.png',
  photos: '/system/image/official/products/photos.png',
  register: '/system/image/official/products/register.png',
  sell_ticket: '/system/image/official/products/sell_ticket.png',
  supplier_management: '/system/image/official/products/supplier_management.png',
  msgvip: '/system/image/official/products/vip.png',
  website: '/system/image/official/products/website.png',
  scene01: '/system/image/official/scene/01.png',
  scene02: '/system/image/official/scene/02.png',
  scene03: '/system/image/official/scene/03.png',
  scene04: '/system/image/official/scene/04.png',
  scene05: '/system/image/official/scene/05.png',
  scene06: '/system/image/official/scene/06.png',
  scene07: '/system/image/official/scene/07.png',
  scene08: '/system/image/official/scene/08.png',
  scene09: '/system/image/official/scene/09.png',
  scene10: '/system/image/official/scene/10.png',
  scene11: '/system/image/official/scene/11.png',
  scene12: '/system/image/official/scene/12.png',
}

module.exports = Assets