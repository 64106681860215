<!-- 案例详情 -->
<template>
  <div class='case_detail_wrap'>
    <div class="case_detail_body">
      <div class="case_detail_content">
        <!-- 返回按钮 -->
        <div class="back_wrap">
          <div
            class="back_btn_wrap"
            @click="handleBack"
          >
            <a-icon
              class="back_icon"
              type="left"
            />
            <span class="back_text">返回上一页</span>
          </div>
        </div>
        <!-- 案例信息 -->
        <div class="case_intro_wrap">
          <div class="case_intro_left">
            <div class="case_company_info">
              <div class="case_company_logo">
                <div
                  v-lazy:background-image="`${apiObj.VUE_APP_BASE_CDN + info.logo}`"
                  class="case_company_img"
                ></div>
              </div>
              <div class="case_company_detail">
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">公司</div>
                  <div class="case_comp_info_text">{{info.company_name}}</div>
                </div>
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">时间</div>
                  <div class="case_comp_info_text">{{info.time_begin + " ~ " +info.time_end}}</div>
                </div>
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">位置</div>
                  <div class="case_comp_info_text">{{info.province + ' / ' + info.city}}</div>
                </div>
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">人数</div>
                  <div class="case_comp_info_text">{{info.quantity}}</div>
                </div>
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">类别</div>
                  <div class="case_comp_info_text">{{info.industry + " / " + info.scene}}</div>
                </div>
                <div class="case_comp_info_item">
                  <div class="case_comp_info_name">产品</div>
                  <div class="case_comp_info_text">{{info.products}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="case_intro_right">
            <div class="case_info_wrap">
              <!-- 活动名称 -->
              <div class="case_info_title">{{info.title}}</div>
              <div class="case_info_cover">
                <img
                  v-if="info.cover"
                  v-lazy="`${apiObj.VUE_APP_BASE_CDN + info.cover}!/fw/1440`"
                >
              </div>
              <div class="case_info_intro">{{info.introduction}}</div>
              <!-- 分隔线 -->
              <div class="case_info_divide"></div>
              <div
                class="case_info_video"
                v-if="info.video"
              >
                <video-player
                  class="case_video_box"
                  :options="playerOptions"
                  :playsinline="true"
                ></video-player>
              </div>

              <!-- 案例详情 -->
              <div class="case_info_body">
                <div
                  class="case_info_item"
                  v-for="(item,index) of info.detail"
                  :key="index"
                >
                  <div
                    class="case_img_wrap"
                    :class="item.layout == 'horizontal' ? 'layout_horizontal_wrap' : ''"
                  >
                    <div
                      class="case_img_bg"
                      :style="`${item.layout == 'horizontal' ? `background-image:url(${apiObj.VUE_APP_BASE_CDN + item.url})` : ''}`"
                    ></div>
                    <img
                      v-lazy="`${apiObj.VUE_APP_BASE_CDN + item.url + (item.layout == 'horizontal' ? '!/fw/750' : '!/fw/1280')}`"
                      alt=""
                      class="case_img"
                      :class="item.layout == 'horizontal' ? 'layout_horizontal' : ''"
                    >
                  </div>
                  <div class="case_img_desc">{{item.intro}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-show="loading" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Icon,
  Tag
} from 'ant-design-vue'
Vue.use(Icon)
Vue.use(Tag)

import Loading from '@c/Loading'

import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

import { GetCaseInfo } from '@a/js/api/official'
import apiObj from '@a/js/api'
export default {
  name: 'CasesDetail',
  data() {
    return {
      apiObj,
      params: {},
      loading: true,
      info: {
        cover: '',
        title: '',
        time_begin: '',
        time_end: '',
        quantity: '',
        products: '',
        introduction: '',
        province: '',
        city: '',
        logo: '',
        company_name: '',
        industry: '',
        scene: '',
        department: '',
        video: '',
        detail: []
      },
      playerOptions: {
        height: 450,
        width: 800,
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          // {
          //   type: "video/mp4",
          //   src: require('@a/video/media.mp4'),
          //   // src: require('@a/video/media.mp4'),
          //   // src: require('@a/video/oceans.mp4'),
          // }
        ],
      }
    };
  },

  components: {
    videoPlayer,
    Loading
  },

  methods: {
    // 获取参数信息
    GetParamsData() {
      return new Promise(resolve => {
        this.params = this.$route.params;
        resolve();
      });
    },
    // 点击返回上一页
    handleBack() {
      this.$router.go(-1)
    },
    // 获取案例详情
    initCaseDetail() {
      let data = {
        case_uid: this.params.caseid
      }
      GetCaseInfo(data).then(res => {

        this.info = res.data
        if (this.info.video) {
          this.playerOptions.sources.push({
            type: "video/mp4",
            src: apiObj.VUE_APP_BASE_CDN + this.info.video,
          })
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })

    }
  },

  mounted() {
    this.GetParamsData().then(() => {
      this.initCaseDetail()
    })
  },
}
</script>
<style lang='less' scoped>
.case_detail_wrap {
  width: 100%;
  // background: #fff;
  background-image: linear-gradient(
    to bottom,
    rgba(193, 233, 253, 0.38) 0px,
    #ffffff 535px
  );
  position: relative;
  padding-bottom: 100px;
  .case_detail_body {
    width: 1200px;
    min-height: 800px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    .case_detail_content {
      width: 100%;
      // 返回按钮
      .back_wrap {
        margin-top: 34px;
        .back_btn_wrap {
          display: inline-block;
          user-select: none;
          cursor: pointer;
          &:hover {
            .back_icon,
            .back_text {
              color: #06f;
            }
          }
          .back_icon {
            font-size: 16px;
            color: #999;
          }
          .back_text {
            font-size: 16px;
            line-height: 24px;
            color: #999;
            margin-left: 5px;
          }
        }
      }
      // 案例信息
      .case_intro_wrap {
        display: flex;
        margin-top: 14px;
        .case_intro_left {
          width: 296px;
          .case_company_info {
            background: #fff;
            border-radius: 4px;
            margin-top: 20px;
            overflow: hidden;
            box-shadow: 0px 0px 10px 0px rgba(0, 80, 191, 0.06),
              0px 20px 30px 0px rgba(0, 80, 191, 0.06);
            .case_company_logo {
              width: 160px;
              height: 56px;
              margin: 24px 68px;
              .case_company_img {
                width: 100%;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
            .case_company_detail {
              padding: 0 30px 24px;
              .case_comp_info_item {
                display: flex;
                margin-bottom: 5px;
                &:last-child {
                  margin-bottom: 0;
                }
                .case_comp_info_name {
                  font-size: 14px;
                  line-height: 24px;
                  width: 30px;
                  text-align: right;
                }
                .case_comp_info_text {
                  flex: 1;
                  font-size: 14px;
                  line-height: 24px;
                  margin-left: 16px;
                  color: rgb(133, 146, 166);
                }
              }
            }
          }
        }
        .case_intro_right {
          flex: 1;
          margin-left: 64px;
          .case_info_wrap {
            // 活动名称
            .case_info_title {
              font-size: 48px;
              line-height: 72px;
              font-weight: 700;
              color: #171d26;
            }
            // 封面图片
            .case_info_cover {
              width: 100%;
              font-size: 0;
              margin-top: 14px;
              img {
                width: 100%;
              }
            }
            // 活动简介
            .case_info_intro {
              font-size: 16px;
              line-height: 28px;
              color: #606c80;
              margin-top: 14px;
              text-align: justify;
            }
            // 分割线
            .case_info_divide {
              width: 100%;
              height: 0.5px;
              background: #eee;
              margin: 40px 0 45px;
            }
            // 视频
            .case_info_video {
              width: 100%;
              margin: 0 auto 24px;
              .case_video_box {
                /deep/ .video-js {
                  .vjs-big-play-button {
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                  }
                }
              }
            }
            // 案例详情
            .case_info_body {
              .case_info_item {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 5px;
                .case_img_wrap {
                  font-size: 0;
                  text-align: center;
                  background: #f5f5f5;
                  position: relative;
                  border-radius: 2px;
                  overflow: hidden;
                  .case_img_bg {
                    position: absolute;
                    width: 120%;
                    height: 120%;
                    top: -10%;
                    left: -10%;
                    z-index: 1;
                    filter: blur(30px);
                    background-repeat: no-repeat;
                    background-size: 100%;
                  }
                  .case_img {
                    width: 100%;
                  }
                  .layout_horizontal {
                    width: 300px;
                    position: relative;
                    z-index: 2;
                    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
                  }
                }
                .layout_horizontal_wrap {
                  padding: 50px 0;
                }
                .case_img_desc {
                  font-size: 16px;
                  text-align: center;
                  padding: 10px 20px 20px;
                  line-height: 28px;
                }
              }
            }
          }
        }
      }
      // 案例简介
      // .case_intro_wrap {
      //   margin-top: 34px;
      //   padding-bottom: 50px;
      //   box-sizing: border-box;
      //   border-bottom: 1px solid #eee;
      //   display: flex;
      //   .case_intro_cover_wrap {
      //     width: 350px;
      //     margin-right: 80px;
      //     display: flex;
      //     align-items: center;
      //     img {
      //       width: 100%;
      //       height: 240px;
      //     }
      //     .case_video_box {
      //       // width: 100%;
      //       // height: 240px;
      //       /deep/ .video-js {
      //         .vjs-big-play-button {
      //           top: 50%;
      //           left: 50%;
      //           transform: translateX(-50%) translateY(-50%);
      //         }
      //       }
      //     }
      //   }
      //   .case_intro_info_wrap {
      //     flex: 1;
      //     min-height: 240px;
      //     display: flex;
      //     flex-direction: column;
      //     .info_name {
      //       font-size: 26px;
      //       line-height: 40px;
      //       letter-spacing: 0.05em;
      //       color: #000;
      //       font-weight: bold;
      //       margin-bottom: 30px;
      //     }
      //     .info_desc {
      //       flex: 1;
      //       font-size: 16px;
      //       line-height: 24px;
      //       color: #777;
      //       text-align: justify;
      //       max-height: 120px;
      //       margin-bottom: 28px;
      //       overflow-y: auto;
      //       &::-webkit-scrollbar {
      //         width: 5px;
      //       }
      //       &::-webkit-scrollbar-track {
      //         border-radius: 10px;
      //         background-color: rgba(0, 0, 0, 0.1);
      //       }
      //       &::-webkit-scrollbar-thumb {
      //         border-radius: 10px;
      //         background-color: rgba(0, 0, 0, 0.1);
      //       }
      //     }
      //   }
      // }
      // 案例详情
      // .case_info_wrap {
      //   padding-top: 30px;
      //   .case_info_item {
      //     width: 80%;
      //     margin: 0 auto;
      //     margin-bottom: 5px;
      //     .case_img_wrap {
      //       font-size: 0;
      //       text-align: center;
      //       background: #f5f5f5;
      //       position: relative;
      //       border-radius: 2px;
      //       overflow: hidden;
      //       .case_img_bg {
      //         position: absolute;
      //         width: 120%;
      //         height: 120%;
      //         top: -10%;
      //         left: -10%;
      //         z-index: 1;
      //         filter: blur(30px);
      //         background-repeat: no-repeat;
      //         background-size: 100%;
      //       }
      //       .case_img {
      //         width: 100%;
      //       }
      //       .layout_horizontal {
      //         width: 300px;
      //         position: relative;
      //         z-index: 2;
      //         box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
      //       }
      //     }
      //     .layout_horizontal_wrap {
      //       padding: 50px 0;
      //     }
      //     .case_img_desc {
      //       font-size: 14px;
      //       text-align: center;
      //       padding: 0 20px;
      //       line-height: 30px;
      //     }
      //   }
      // }
    }
  }
}
</style>