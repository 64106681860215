import request from '@a/js/request'

// 查询版权信息
export function GetVersionOption(query) {
  return request({
    url: '/g/v1/version',
    method: 'get',
    params: query,
  })
}

// 获取单个项目信息
export function GetProjectInfo(query) {
  return request({
    url: '/b/base/v1/project',
    method: 'get',
    params: query,
  })
}

// 修改项目
export function ModifyProject(params) {
  return request({
    url: '/b/base/v1/project',
    method: 'put',
    data: params,
  })
}

// 新增项目
export function AddProject(params) {
  return request({
    url: '/b/base/v1/project',
    method: 'post',
    data: params,
  })
}

// 获取用户信息
export function GetUserInfo(query) {
  return request({
    url: '/b/base/v1/user',
    method: 'get',
    params: query,
  })
}

/**
 * 图片上传前校验
 * @param file 待上传的文件对象
 */

export function BeforeImgUpload(file) {
  const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg'
  const isPNG = file.type === 'image/png'

  if (!(isJPG || isPNG)) {
    this.$message.error('只支持上传.jpg，.jpeg，.png格式的图片!')
  }
  const isLt2M = file.size / 1024 / 1024 < 5
  if (!isLt2M) {
    this.$message.error('文件大小不能超过 5MB!')
  }
  return (isJPG || isPNG) && isLt2M
}

/**
 * 文件上传前校验
 * @param file 待上传的文件对象
 */

export function BeforeFileUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 5
  if (!isLt2M) {
    this.$message.error('文件大小不能超过 5MB!')
  }
  return isLt2M
}

// 文件上传鉴权
export function UploadAuthentication(params) {
  return request({
    url: '/g/v1/upload/upyun',
    method: 'post',
    data: params,
  })
}

// 文件上传
export function Upload(params) {
  return request({
    url: '/g/v1/upload',
    method: 'post',
    data: params,
  })
}

// 查询区域编码
export function GetAreaCode(query) {
  return request({
    url: '/g/v1/pca/code',
    method: 'get',
    params: query,
  })
}

// 获取系统默认项目封面图
export function GetDefaultProCover(query) {
  return request({
    url: '/g/v1/assets',
    method: 'get',
    params: query,
  })
}

// 站内信息列表查询
export function GetMarketList(params) {
  return request({
    url: '/b/base/v1/letter/list',
    method: 'post',
    data: params,
  })
}

// 查询用户办会币余额
export function GetUserBalance(query) {
  return request({
    url: '/g/v1/banhuibi',
    method: 'get',
    params: query,
  })
}

// 获取短信模板列表
export function GetSmsTeamplateList(query) {
  return request({
    url: '/g/v1/message/sms/template/titles',
    method: 'get',
    params: query,
  })
}

// 选择模板调用接口 --- 弃用
export function PickedTemplate(query) {
  return request({
    url: '/g/v1/message/sms/template/used',
    method: 'get',
    params: query,
  })
}

// 获取token
export function RefreshToken(params) {
  return request({
    url: '/g/v1/token',
    method: 'post',
    data: params,
  })
}

// 下载文件鉴权
export function DownloadAuth(params) {
  return request({
    url: '/g/v1/download/upyun/auth',
    method: 'post',
    data: params,
  })
}