<!-- 官网首页 -->
<template>
  <div class='container'>
    <!-- 顶部导航 -->
    <NavBar :isSolid="isSolid" />
    <!-- 官网头部 -->
    <Header />
    <!-- 应用简介 -->
    <Application />
    <!-- 场景介绍 -->
    <Scenes />
    <!-- 案例简介 -->
    <Cases />
    <!-- 合作伙伴 -->
    <Partners />
    <!-- 页脚 -->
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Button,
} from 'ant-design-vue'
Vue.use(Button)

import NavBar from '@c/Navbar'
import Header from './components/header'
import Application from './components/application'
import Scenes from './components/scenes'
import Cases from './components/cases'
import Partners from './components/partners'
import Footer from '@c/Footer'
export default {
  name: 'Index',
  data() {
    return {
      isSolid: false,
      isScenes: false
    };
  },

  components: {
    NavBar,
    Header,
    Application,
    Scenes,
    Cases,
    Partners,
    Footer
  },

  methods: {
    handleScroll() {
      let scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (scroll >= 10) {
        this.isSolid = true
      } else {
        this.isSolid = false
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang='less' scoped>
.container {
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  position: relative;
  // overflow-x: hidden;
}
</style>