<!-- 加载中 -->
<template>
  <div class='is_load_wrap'>
    <a-spin
      :tip="loadtext"
      class="is_load_spin"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { Spin } from 'ant-design-vue'
Vue.use(Spin)

export default {
  name: 'Loading',
  props: {
    loadtext: {
      type: String,
      default: '数据请求中...'
    }
  },
  data() {
    return {

    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.is_load_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.8);
  .is_load_spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>