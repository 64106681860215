<!-- 案例展示 -->
<template>
  <div class='case_wrap'>
    <div
      class="case_body"
      data-aos="zoom-in-up"
    >
      <div class="case_title">助力 <span class="case_title_sty">25000+</span> 场会议成功举办</div>
      <div class="case_content_wrap">
        <div class="case_content">
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="item of caseList"
              :key="item.uid"
            >
              <div class="case_slider_wrap">
                <div
                  class="case_img_wrap"
                  :style="`backgroundImage:url(${apiObj.VUE_APP_BASE_CDN +item.cover}!/fh/880)`"
                >
                  <!-- v-lazy:background-image="`${item.cover}!/fw/500`" -->
                </div>
                <div class="case_slider_content">
                  <div class="case_tag_wrap">
                    <a-tag
                      class="case_tag_item"
                      color="#FBA337"
                    >
                      {{item.industry}}
                    </a-tag>
                    <a-tag
                      class="case_tag_item"
                      color="#FBA337"
                    >
                      {{item.scene}}
                    </a-tag>
                  </div>
                  <div class="case_project_name">{{item.title}}</div>
                  <div class="case_project_desc">{{item.introduction}}</div>
                  <div class="case_product_wrap">
                    <div class="case_product_body">
                      <div
                        class="case_product_item_wrap"
                        v-for="(proitem,index) of item.prolist"
                        :key="index"
                      >
                        <div
                          v-if="proitem"
                          class="case_product_item"
                        >
                          <a-icon
                            class="case_product_icon"
                            type="check-circle"
                            theme="filled"
                          />
                          <div class="case_product_text">{{proitem}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="case_detail_wrap">
                    <a-button
                      class="case_detail_btn"
                      type="link"
                      size="small"
                      :data-uid="item.uid"
                    >
                      了解详情
                      <a-icon type="right" />
                    </a-button>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Tag,
  Icon
} from 'ant-design-vue'
Vue.use(Tag)
Vue.use(Icon)

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { GetCaseList } from '@a/js/api/official'

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'
import apiObj from '@a/js/api'
export default {
  name: 'Cases',
  data() {
    return {
      apiObj,
      swiperOptions: {
        // pagination: {
        //   el: '.swiper-pagination'
        // },
        // autoplay: true,
        autoplay: {
          stopOnLastSlide: false,
          disableOnInteraction: true,
          delay: 4000
        },
        speed: 1000,
        loop: true,
        on: {
          click: (event) => {
            let uid = event.target.dataset.uid
            if (uid) {
              this.$router.push({
                name: 'CasesIntro',
                params: {
                  caseid: uid
                }
              })
            }
          }
        }
      },

      caseList: []
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },

  components: {
    Swiper,
    SwiperSlide
  },

  methods: {
    // 获取官网案例列表
    initOfficialCaseList() {
      let data = {
        page: 1,
        page_size: 5,
        is_show_official_home: 1
      }
      GetCaseList(data).then(res => {
        let lists = res.data.list
        lists.forEach(item => {
          let arr = []
          if (item.products) {
            arr = item.products.split(';')
          }
          item.prolist = arr
        })
        // console.log(lists)
        this.caseList = lists
      }).catch(() => {

      })
    },
  },

  mounted() {
    this.initOfficialCaseList()
    this.swiper.el.onmouseover = () => {
      this.swiper.autoplay.stop()
    }
    this.swiper.el.onmouseleave = () => {
      this.swiper.autoplay.start()
    }
  },
}
</script>
<style lang='less' scoped>
.case_wrap {
  width: 100%;
  background: #f7fcff;
  .case_body {
    width: 1200px;
    padding: 100px 0;
    margin: 0 auto;
    .case_title {
      text-align: center;
      font-size: 44px;
      line-height: 66px;
      letter-spacing: 0.05em;
      font-weight: bold;
      color: #000;
      user-select: none;
      .case_title_sty {
        color: #06f;
        font-size: 66px;
      }
    }
    .case_content_wrap {
      margin-top: 70px;
      padding: 0 40px;
      .case_content {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        overflow: hidden;
        .case_slider_wrap {
          display: flex;
          height: 440px;
          .case_img_wrap {
            width: 500px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // img {
            //   width: 100%;
            // }
          }
          .case_slider_content {
            flex: 1;
            min-width: 600px;
            padding: 40px;
            box-sizing: border-box;
            background: #fff;
            .case_tag_wrap {
              margin-bottom: 25px;
            }
            .case_project_name {
              font-size: 24px;
              line-height: 36px;
              letter-spacing: 0.05em;
              color: #000;
              font-weight: bold;
              margin-bottom: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              white-space: nowrap;
            }
            .case_project_desc {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.05em;
              color: #606c80;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              margin-bottom: 24px;
            }
            .case_product_wrap {
              height: 160px;
              background: #f5f7fa;
              border-radius: 2px;
              padding: 24px;
              box-sizing: border-box;
              .case_product_body {
                overflow-y: auto;
                height: 100%;
                &::-webkit-scrollbar {
                  width: 5px;
                }
                &::-webkit-scrollbar-track {
                  border-radius: 10px;
                  background-color: rgba(0, 0, 0, 0.1);
                }
                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  background-color: rgba(0, 0, 0, 0.1);
                }
              }
              .case_product_item_wrap {
                margin-bottom: 16px;
                &:last-child {
                  margin-bottom: 0;
                }
                .case_product_item {
                  display: flex;
                  align-items: center;
                  .case_product_icon {
                    color: #51cb80;
                  }
                  .case_product_text {
                    flex: 1;
                    margin-left: 8px;
                    font-size: 14px;
                    line-height: 22px;
                    color: #202d40;
                    margin-top: 2px;
                  }
                }
              }
            }
            .case_detail_wrap {
              text-align: right;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
</style>