<!-- 方案首页 -->
<template>
  <div class='plan_home_wrap'>
    <div class="plan_body">
      <!-- 标题 -->
      <Title
        :title="'解决方案'"
        :desc="'面向办会目标的体系化流程、产品、服务组合'"
      />
      <!-- 案例列表 -->
      <div class="plan_list_wrap">
        <div
          class="plan_item_wrap"
          v-for="(item,index) of planlist"
          :key="item.id"
          :data-aos="index%2 === 0 ? 'zoom-out-right' : 'zoom-out-left'"
        >
          <div
            class="plan_img_wrap"
            v-lazy:background-image="item.img"
          >
            <!-- <img v-lazy="item.img"> -->
          </div>
          <div class="plan_info_wrap">
            <div class="plan_info">
              <div class="plan_title">{{item.name}}</div>
              <div
                v-show="item.desc"
                class="plan_desc"
              >{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@c/Title'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'PlansHome',
  data() {
    return {
      planlist: [
        {
          id: 'plan01',
          name: '重要嘉宾会议解决方案',
          desc: '订货会 渠道商大会 招商会 订货会',
          img: apiObj.VUE_APP_BASE_CDN + Assets.plansvip
        },
        {
          id: 'plan02',
          name: '培训行业会议解决方案',
          desc: '',
          img: apiObj.VUE_APP_BASE_CDN + Assets.train
        },
        {
          id: 'plan03',
          name: '学术会议解决方案',
          desc: '',
          img: apiObj.VUE_APP_BASE_CDN + Assets.academic
        },
        {
          id: 'plan04',
          name: '线上会议解决方案',
          desc: '',
          img: apiObj.VUE_APP_BASE_CDN + Assets.online
        },
        {
          id: 'plan05',
          name: '签约会议解决方案',
          desc: '',
          img: apiObj.VUE_APP_BASE_CDN + Assets.planscontract
        },
      ]
    };
  },

  components: {
    Title
  },

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.plan_home_wrap {
  width: 100%;
  .plan_body {
    width: 1200px;
    margin: 0 auto;
    overflow-y: hidden;
    .plan_list_wrap {
      padding: 0 100px;
      .plan_item_wrap {
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        border-radius: 2px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        margin-bottom: 50px;
        padding: 25px 30px;
        padding-right: 50px;
        display: flex;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 2px 8px rgba(0, 102, 225, 0.1);
        }
        .plan_img_wrap {
          width: 400px;
          height: 250px;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 2px;
          // img {
          //   width: 100%;
          //   height: 100%;
          // }
        }
        .plan_info_wrap {
          flex: 1;
          margin-left: 50px;
          display: flex;
          align-items: center;
          .plan_info {
            display: inline-block;
            text-align: center;
            margin: 0 auto;
            user-select: none;
            .plan_title {
              font-size: 30px;
              line-height: 44px;
              font-weight: bold;
              letter-spacing: 0.1em;
              color: #000;
            }
            .plan_desc {
              font-size: 16px;
              line-height: 24px;
              color: #000;
              letter-spacing: 0.1em;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>