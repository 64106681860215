<!-- 页面标题 -->
<template>
  <div class='title_wrap'>
    <div
      class="title_text"
      data-aos="fade-left"
    >{{title}}</div>
    <div
      class="title_desc"
      data-aos="fade-left"
    >{{desc}}</div>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: ""
    },
    desc: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.title_wrap {
  padding: 135px 0;
  background: #fff;
  .title_text {
    font-size: 44px;
    line-height: 66px;
    letter-spacing: 0.1em;
    color: #000;
    font-weight: bold;
    text-align: center;
    user-select: none;
  }
  .title_desc {
    font-size: 18px;
    line-height: 26px;
    color: #000;
    letter-spacing: 0.1em;
    text-align: center;
    user-select: none;
  }
}
</style>