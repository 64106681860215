<!-- 合作伙伴 -->
<template>
  <div class='partner_wrap'>
    <div
      class="partner_body"
      data-aos="zoom-in-up"
    >
      <div class="partner_title">
        历经 <span class="title_sty">5</span> 年，与众多品牌精诚合作
      </div>
      <div class="partner_content">
        <div
          class="partner_img"
          v-lazy:background-image="partners"
        ></div>
      </div>
      <div class="register_wrap">
        <div class="register_body">
          <div
            class="register_img"
            v-lazy:background-image="registerBg"
          ></div>
          <div class="register_content_wrap">
            <div class="register_title">立即开启高效的办会体验</div>
            <a-button
              class="register_btn"
              size="large"
              @click="handleWannaRegister"
            >
              免费注册
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'Partners',
  data() {
    return {
      partners: apiObj.VUE_APP_BASE_CDN + Assets.partners,
      registerBg: apiObj.VUE_APP_BASE_CDN + Assets.register_bg,
      // registerBg: require('@a/img/temp/regist1.png'),
    };
  },

  components: {},

  methods: {
    // 点击注册 
    handleWannaRegister() {
      let url = apiObj.VUE_APP_BASE_SERVE + 'base/register'
      window.open(url)
    },
  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.partner_wrap {
  width: 100%;
  background: #fff;
  .partner_body {
    width: 100%;
    padding: 100px 0 150px;
    .partner_title {
      font-size: 44px;
      line-height: 66px;
      letter-spacing: 0.05em;
      color: #000;
      font-weight: bold;
      text-align: center;
      margin-bottom: 70px;
      .title_sty {
        font-size: 66px;
        color: #06f;
      }
    }
    .partner_content {
      width: 100%;
      height: 500px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      margin-bottom: 90px;
      &:hover {
        .partner_img {
          transition-delay: 0.2s;
          transform: scale3d(0.5, 0.5, 1);
        }
      }
      .partner_img {
        position: absolute;
        box-sizing: border-box;
        top: -200%;
        right: -200%;
        bottom: -200%;
        left: -200%;
        width: 500%;
        height: 500%;
        background-repeat: repeat;
        background-position: 50% 50%;
        transition: all 0.8s ease;
      }
    }
    .register_wrap {
      .register_body {
        margin: 0 auto;
        width: 1100px;
        height: 260px;
        position: relative;
        background: linear-gradient(270deg, #0066ff 0%, #0066ff 90%);
        border-radius: 2px;
        .register_img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          // background-size: 90%;
          background-size: 100%;
          background-position: center;
        }
        .register_content_wrap {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          text-align: center;
          .register_title {
            font-size: 40px;
            line-height: 60px;
            letter-spacing: 0.05em;
            color: #fff;
            font-weight: bold;
            margin-bottom: 40px;
            user-select: none;
          }
          .register_btn {
            color: #06f;
            font-weight: 500;
            letter-spacing: 0.05em;
            transition: all 0.3s ease;
            border-radius: 2px;
            height: 50px;
            line-height: 50px;
            width: 150px;
            font-size: 16px;
            &:hover {
              transform: translateY(-4px);
            }
          }
        }
      }
    }
  }
}
</style>