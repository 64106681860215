<!-- 页面内容 -->
<template>
  <div class='footer_wrap'>
    <div class="footer_info_wrap">
      <div class="footer_body">
        <div class="company_info_wrap">
          <div
            class="logo_img"
            v-lazy:background-image="IMG_LOGO_WHITE"
          ></div>
          <div class="company_phone">15817279516</div>
          <div class="company_email">market@banhuijia.com</div>
          <div class="company_email">official@eventist.cn</div>
          <div
            v-show="false"
            class="company_contact_warp"
          >
            <a-icon
              class="company_contact_icon"
              type="wechat"
            />
            <a-icon
              class="company_contact_icon"
              type="weibo"
            />
            <a-icon
              class="company_contact_icon"
              type="zhihu"
            />
          </div>
        </div>
        <div
          class="footer_class_wrap"
          v-for="item of infos"
          :key="item.id"
        >
          <div class="footer_class_name">{{ item.title }}</div>
          <div
            class="footer_class_item"
            v-for="(innerItem, index) of item.lists"
            :key="index"
            @click="handleFooterItem(innerItem)"
          >
            {{ innerItem.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer_copyright_wrap">
      <div class="footer_copyright">
        © 2019 办会家 版权所有 |
        <span
          class="footer_copyright_num"
          @click="handleCopyright"
        >{{
          copyright
        }}</span>
      </div>
      <!-- 粤 ICP 备 19086685 号 -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'ant-design-vue'
Vue.use(Icon)

import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'Footer',
  data() {
    return {
      IMG_LOGO_WHITE: apiObj.VUE_APP_BASE_CDN + Assets.logo_white,
      infos: [
        {
          id: 'subsection',
          title: '城市分部',
          lists: [
            {
              name: '深圳（总部）',
              url: '',
            },
            {
              name: '广州',
              url: '',
            },
            // {
            //   name: '陕西',
            //   url: ''
            // }
          ],
        },
        {
          id: 'service',
          title: '产品服务',
          lists: [
            {
              name: '价格',
              type: 'inner',
              url: 'Prices',
            },
            // {
            //   name: '产品',
            //   type: 'inner',
            //   url: 'Products'
            // },
            {
              name: '方案',
              type: 'inner',
              url: 'Plans',
            },
            {
              name: '案例',
              type: 'inner',
              url: 'Cases',
            },
          ],
        },
        {
          id: 'cooperate1',
          title: '市场合作',
          lists: [
            // {
            //   name: '赞助合作',
            //   url: ''
            // },
            // {
            //   name: '新闻报道',
            //   url: ''
            // },
            {
              name: '设计素材',
              type: 'inner',
              url: 'MaterialHome',
            },
            // {
            //   name: '官方博客',
            //   url: ''
            // }
          ],
        },
        {
          id: 'company',
          title: '公司',
          lists: [
            {
              name: '关于我们',
              url: '',
            },
            // {
            //   name: '新闻报道',
            //   url: ''
            // },
            {
              name: '联系我们',
              url: '',
            },
          ],
        },
      ],
    }
  },

  computed: {
    copyright() {
      let host = window.location.host
      let arr = [
        'www.banhuiyun.com',
        'banhuiyun.com',
        'www.banhuijia.com',
        'banhuijia.com',
      ]
      let index = arr.indexOf(host)
      return index >= 2 ? '粤 ICP 备 19086685 号-3' : '粤 ICP 备 19086685 号'
    },
  },

  components: {},

  methods: {
    // 点击底部按钮
    handleFooterItem(info) {
      console.log(info)
      if (info.type && info.type === 'inner') {
        this.$router.push({
          name: info.url,
        })
      }
    },
    // 点击备案号
    handleCopyright() {
      let url = 'https://beian.miit.gov.cn/'
      window.open(url)
    },
  },

  mounted() { },
}
</script>
<style lang="less" scoped>
.footer_wrap {
  width: 100%;
  background: #202d40;
  .footer_info_wrap {
    width: 1200px;
    margin: 0 auto;
    .footer_body {
      width: 100%;
      box-sizing: border-box;
      padding: 70px 40px;
      display: flex;
      .company_info_wrap {
        flex: 1.5;
        .logo_img {
          height: 30px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-bottom: 30px;
        }
        .company_phone,
        .company_email {
          font-size: 14px;
          line-height: 16px;
          color: #fff;
          letter-spacing: 0.05em;
          margin-bottom: 20px;
        }
        .company_contact_warp {
          .company_contact_icon {
            font-size: 22px;
            color: #fff;
            margin-right: 20px;
            cursor: pointer;
            &:hover {
              color: #06f;
            }
          }
        }
      }
      .footer_class_wrap {
        flex: 1;
        .footer_class_name {
          font-size: 18px;
          line-height: 26px;
          font-weight: bold;
          letter-spacing: 0.05em;
          color: #fff;
          margin-bottom: 20px;
        }
        .footer_class_item {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          color: #fff;
          margin-bottom: 15px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .footer_copyright_wrap {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #3f4c5f;
    position: relative;
    .footer_copyright {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: #fff;
    }
    .footer_copyright_num {
      cursor: pointer;
    }
  }
}
</style>
