<!-- 应用模板 -->
<template>
  <div
    class='app_item_wrap'
    :class="color ? 'color_sty' : ''"
  >
    <div
      class="app_item_body"
      :data-aos="animate"
    >
      <div
        class="app_img_wrap"
        :style="{backgroundImage: 'url(' + introImg + ')'}"
      >
      </div>
      <div class="app_intro_wrap">
        <div class="app_intro_title">{{title}}</div>
        <div class="app_intro_desc">{{introInfo}}</div>
        <div class="app_download_wrap">
          <slot name="download"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    color: {
      type: Boolean,
      default: false
    },
    introImg: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    introInfo: {
      type: String,
      default: ''
    },
    animate: {
      type: String,
      default: 'fade-in'
    }
  },
  data() {
    return {

    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.app_item_wrap {
  width: 100%;
  background: #fff;
  .app_item_body {
    width: 1200px;
    height: 550px;
    box-sizing: border-box;
    padding: 100px 0;
    display: flex;
    margin: 0 auto;
    .app_img_wrap {
      width: 620px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .app_intro_wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-right: 110px;
      .app_intro_title {
        font-size: 30px;
        font-weight: bold;
        line-height: 46px;
        letter-spacing: 0.05em;
        color: #000;
        margin-bottom: 30px;
      }
      .app_intro_desc {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #000;
        flex: 1;
        overflow-y: auto;
        margin-bottom: 20px;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.1);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
.color_sty {
  background: #f7fcff;
}
</style>