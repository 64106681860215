<!-- 产品首页 -->
<template>
  <div class='products_wrap'>
    <!-- 顶部导航 -->
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Button,
} from 'ant-design-vue'
Vue.use(Button)

import NavBar from '@c/Navbar'
import Footer from '@c/Footer'
export default {
  name: 'Products',
  data() {
    return {

    };
  },

  components: {
    NavBar,
    Footer
  },

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.products_wrap {
  width: 100%;
  padding-top: 70px;
  overflow-x: hidden;
}
</style>