<!-- 页面导航 -->
<template>
  <div
    class='nav_wrap'
    :class="isSolid ? 'is_shadow' : ''"
  >
    <div class="nav_content">
      <div
        class="nav_logo_wrap"
        @click="handleLogo"
      >
        <img
          class="logo_img"
          :src="logoImg"
          alt="logo"
        >
      </div>
      <div class="nav_body_wrap">
        <div class="nav_body">
          <div
            class="nav_item"
            :class="item.active ? 'active_nav': ''"
            v-for="item of navList"
            :key="item.id"
            @click="handleNav(item)"
          >
            <div class="nav_item_body">
              <img
                class="nav_item_hot"
                v-show="item.hot"
                :src="HOT"
              >{{item.name}}
            </div>

          </div>
        </div>
      </div>
      <div class="nav_btn_wrap">
        <a-button
          class="nav_btn nav_login_btn"
          type="link"
          @click="handleWannaLogin"
        >
          登录
        </a-button>
        <a-button
          class="nav_btn nav_register_btn"
          type="primary"
          @click="handleWannaRegister"
        >
          注册
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>

import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'NavBar',
  props: {
    isSolid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      IMG_LOGO_WHITE: apiObj.VUE_APP_BASE_CDN + Assets.logo_white,
      IMG_LOGO_BLUE: apiObj.VUE_APP_BASE_CDN + Assets.logo_blue,
      HOT: apiObj.VUE_APP_BASE_CDN + Assets.hot,
      activeLink: '',
      navList: [
        // {
        //   id: 'products',
        //   name: "产品",
        //   active: false,
        //   link: 'ProductsHome'
        // },
        {
          id: 'apps',
          name: "应用",
          active: false,
          link: 'AppsHome',
          hot: true
        },
        {
          id: 'plans',
          name: "方案",
          active: false,
          link: 'PlansHome',
          hot: false
        },
        {
          id: 'cases',
          name: "案例",
          active: false,
          link: 'CasesHome',
          hot: false
        },
        {
          id: 'prices',
          name: "价格",
          active: false,
          link: 'PricesHome',
          hot: false
        },
      ]
    };
  },

  computed: {
    logoImg() {
      // return this.isSolid ? this.IMG_LOGO_BLUE : this.IMG_LOGO_WHITE
      return this.IMG_LOGO_BLUE
    }
  },

  components: {},

  methods: {
    // 点击导航
    handleNav(info) {
      // console.log(item)
      if (info.id !== this.activeLink) {
        this.navList.map(item => {
          if (item.id === info.id) {
            item.active = true
            this.activeLink = item.id
            this.$router.push({
              name: item.link
            })
          } else {
            item.active = false
          }
        })
      }
    },
    // 初始化nav
    initNavActive() {
      let path = this.$route.path
      let pathStr = path.split('/')[1]
      this.navList.map(item => {
        if (item.id === pathStr) {
          item.active = true
          this.activeLink = pathStr
        } else {
          item.active = false
        }
      })
    },
    // 点击Logo
    handleLogo() {
      this.$router.push({
        name: "Home"
      })
    },
    // 点击注册 
    handleWannaRegister() {
      let url = apiObj.VUE_APP_BASE_SERVE + 'base/register'
      window.open(url)
    },
    // 点击前往登录
    handleWannaLogin() {
      let url = apiObj.VUE_APP_BASE_SERVE + 'base/login'
      window.open(url)
    }
  },

  mounted() {
    this.initNavActive()
  },
}
</script>
<style lang='less' scoped>
.nav_wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.5s;
  background: #fff;

  &.is_shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  .nav_content {
    width: 100%;
    max-width: 1200px;
    height: 70px;
    margin: 0 auto;
    box-sizing: border-box;
    // padding: 15px 0 15px 15px;
    padding: 15px 0;
    display: flex;
    .nav_logo_wrap {
      font-size: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .logo_img {
        height: 30px;
      }
    }
    .nav_body_wrap {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      .nav_body {
        margin: 0 auto;
        .nav_item {
          display: inline-block;
          margin: 0 20px;
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          letter-spacing: 0.1em;
          color: #000;
          user-select: none;
          &:hover {
            color: #06f;
          }
          .nav_item_body {
            .nav_item_hot {
              height: 16px;
              margin-right: 5px;
              top: 2px;
              position: relative;
            }
          }
        }
        .active_nav {
          color: #06f !important;
        }
      }
    }
    .nav_btn_wrap {
      display: flex;
      align-items: center;
      .nav_btn {
        margin-left: 15px;
        height: 40px;
        font-size: 16px;
        letter-spacing: 0.1em;
        font-weight: 500;
        &:first-child {
          margin-left: 0;
        }
      }
      .nav_login_btn {
        letter-spacing: 4px;
        color: #06f;
        &:hover {
          color: #06f;
        }
      }
      .nav_register_btn {
        background-color: #06f;
        border-color: #06f;
        border-radius: 2px;
      }
    }
  }
}
</style>