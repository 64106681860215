<!-- 桌面签到 -->
<template>
  <AppCard
    :color="true"
    :animate="'fade-right'"
    :introImg="introImg"
    :title="'桌面签到'"
    :introInfo="'适用于人数多、签到时间紧的场景。支持：扫码、姓名、手机四位、邮箱名、身份证、RFID等验证方式。可连接扫码枪、微光扫码、打印机、身份证识别器、RFID等硬件设备。'"
  >
    <template #download>
      <div class="pccheck_download_wrap">
        <div class="pccheck_download_btn_wrap">
          <DownloadBtn
            :icon="'windows'"
            :text="'Windows 下载'"
            class="windows_download_btn"
            @listenHandleClick="hanldeDownloadWin"
          />
          <DownloadBtn
            :isSolid="false"
            :icon="'apple'"
            :text="'Mac 下载'"
            @listenHandleClick="hanldeDownloadMac"
          />
        </div>
        <div class="pccheck_download_text_wrap">
          配套驱动：<span
            class="text_sty"
            @click="handleDownloadID"
          >身份证识别器驱动、</span><span
            class="text_sty"
            @click="handleDownloadPrinter"
          >胸牌打印机驱动</span>
        </div>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from './appcard'
import DownloadBtn from './downloadBtn'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'PcCheck',
  data() {
    return {
      introImg: apiObj.VUE_APP_BASE_CDN + Assets.desktop_check,
      orgwlink: '/apps/check_pc/win/%E7%AD%BE%E5%88%B0_%E6%A1%8C%E9%9D%A2%E7%89%88%20Setup.exe',
      orgmlink: '/apps/check_pc/mac/%E7%AD%BE%E5%88%B0_%E6%A1%8C%E9%9D%A2%E7%89%88%20Setup.dmg',
      orgplink: '/apps/check_pc/win/drivers/gprinter_driver.zip',
      orgidlink: '/apps/check_pc/win/drivers/id_card_scanner_driver.zip',
      wlink: '',
      mlink: '',
      plink: '',
      idlink: ''
    };
  },

  components: {
    AppCard,
    DownloadBtn
  },

  methods: {
    // 初始化链接地址
    initLinks() {
      this.wlink = apiObj.VUE_APP_BASE_CDN + this.orgwlink + '?v=' + new Date().getTime()
      this.mlink = apiObj.VUE_APP_BASE_CDN + this.orgmlink + '?v=' + new Date().getTime()
      this.plink = apiObj.VUE_APP_BASE_CDN + this.orgplink + '?v=' + new Date().getTime()
      this.idlink = apiObj.VUE_APP_BASE_CDN + this.orgidlink + '?v=' + new Date().getTime()
    },
    // 下载身份证识别驱动
    handleDownloadID() {
      window.location.href = this.idlink
    },
    // 下载打印机驱动
    handleDownloadPrinter() {
      window.location.href = this.plink
    },
    // 下载window版本
    hanldeDownloadWin() {
      window.location.href = this.wlink
    },
    // 下载mac版本
    hanldeDownloadMac() {
      window.location.href = this.mlink
    }
  },

  mounted() {
    this.initLinks()
  },
}
</script>
<style lang='less' scoped>
.pccheck_download_wrap {
  .pccheck_download_btn_wrap {
    margin-bottom: 20px;
    .windows_download_btn {
      margin-right: 20px;
    }
  }
  .pccheck_download_text_wrap {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #000;
    .text_sty {
      color: #06f;
      cursor: pointer;
    }
  }
}
</style>