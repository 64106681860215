<!-- 使用场景 -->
<template>
  <div class='scene_wrap'>
    <div
      class="scene_body"
      data-aos="zoom-in-up"
    >
      <div class="scene_title">{{title}}</div>
      <div class="scene_content">
        <div
          class="scene_item_wrap"
          v-for="item of sceneList"
          :key="item.id"
        >
          <div
            class="scene_item"
            v-lazy:background-image="item.img"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'Scenes',
  data() {
    return {
      title: '深度匹配组织的各类办会场景',
      sceneList: [
        {
          id: 'scene01',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene01,
        },
        {
          id: 'scene02',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene02,
        },
        {
          id: 'scene03',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene03,
        },
        {
          id: 'scene04',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene04,
        },
        {
          id: 'scene05',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene05,
        },
        {
          id: 'scene06',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene06,
        },
        {
          id: 'scene07',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene07,
        },
        {
          id: 'scene08',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene08,
        },
        {
          id: 'scene09',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene09,
        },
        {
          id: 'scene10',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene10,
        },
        {
          id: 'scene11',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene11,
        },
        {
          id: 'scene12',
          img: apiObj.VUE_APP_BASE_CDN + Assets.scene12,
        },
      ]
    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.scene_wrap {
  width: 100%;
  background: #fff;
  .scene_body {
    width: 1200px;
    margin: 0 auto;
    // padding: 100px 0 70px;
    padding: 40px 0 70px;
    .scene_title {
      font-size: 44px;
      line-height: 66px;
      letter-spacing: 0.05em;
      font-weight: bold;
      color: #000;
      text-align: center;
      user-select: none;
    }
    .scene_content {
      margin-top: 70px;
      .scene_item_wrap {
        width: 25%;
        box-sizing: border-box;
        padding: 0 15px;
        margin-bottom: 30px;
        display: inline-block;
        .scene_item {
          height: 182px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>