<!-- 页面顶部 -->
<template>
  <div class='header_wrap'>
    <!-- 图文部分 -->
    <div class="header_top_wrap">
      <div class="header_top_left">
        <img
          class="header_top_left_text"
          :src="headerText"
        >
        <div class="header_top_left_btn_wrap">
          <div
            class="header_top_left_btn primary_btn"
            @click="handleWannaRegister"
          >免费注册</div>
          <div
            class="header_top_left_btn"
            @click="handleWannaVideo"
          >观看视频</div>
        </div>
      </div>
      <img
        class="header_top_right"
        :src="headerImg"
      >
    </div>
    <!-- 顶部数据统计 -->
    <div class="header_static_wrap">
      <div class="header_static_body">
        <div
          class="header_static_item"
          :style="{width: '200px'}"
        >
          <div class="static_item_img_wrap">
            <img
              :src="attendeeImg"
              class="static_item_img"
            >
          </div>
          <div class="static_item_info">
            <div class="static_item_number">{{ (guest  == '1,000,000' || guest == '-') ? guest : Math.floor(guest) }}</div>
            <div class="static_item_text">参会人数</div>
          </div>
        </div>
        <div
          class="header_static_item"
          :style="{width: '166px'}"
        >
          <div class="static_item_img_wrap">
            <img
              :src="sessionImg"
              class="static_item_img"
            >
          </div>
          <div class="static_item_info">
            <div class="static_item_number">{{(meeting  == '25,000' || meeting == '-') ? meeting : Math.floor(meeting) }}</div>
            <div class="static_item_text">办会场次</div>
          </div>
        </div>
        <div
          class="header_static_item"
          :style="{width: '152px'}"
        >
          <div class="static_item_img_wrap">
            <img
              :src="sponsorImg"
              class="static_item_img"
            >
          </div>
          <div class="static_item_info">
            <div class="static_item_number">{{(sponsor  == '1,000' || sponsor == '-') ? sponsor : Math.floor(sponsor)}}</div>
            <div class="static_item_text">主办方</div>
          </div>
        </div>
        <div class="header_static_item">
          <div class="static_item_img_wrap">
            <img
              :src="miceImg"
              class="static_item_img"
            >
          </div>
          <div class="static_item_info">
            <div class="static_item_number">{{(mechanism  == '300' || mechanism == '-') ? mechanism : Math.floor(mechanism)}}</div>
            <div class="static_item_text">MICE公司</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频播放 -->
    <CusVideo v-bind:isvideo.sync="isvideo" />
  </div>
</template>

<script>
import { TweenLite } from 'gsap'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
import CusVideo from './video.vue'

export default {
  name: 'Header',
  data() {
    return {
      headerImg: apiObj.VUE_APP_BASE_CDN + Assets.focus_01,
      headerText: apiObj.VUE_APP_BASE_CDN + Assets.focus_01_text,
      attendeeImg: apiObj.VUE_APP_BASE_CDN + Assets.attendee,
      miceImg: apiObj.VUE_APP_BASE_CDN + Assets.mice,
      sessionImg: apiObj.VUE_APP_BASE_CDN + Assets.session,
      sponsorImg: apiObj.VUE_APP_BASE_CDN + Assets.sponsor,
      guest: '-',
      meeting: '-',
      sponsor: '-',
      mechanism: '-',
      isvideo: false
    };
  },

  components: {
    CusVideo
  },

  methods: {
    // 数据统计动销
    anniStatistics() {
      setTimeout(() => {
        TweenLite.to(this.$data, {
          duration: 1,
          guest: 1000000,
          onComplete: () => {
            this.guest = '1,000,000'
          }
        })
        TweenLite.to(this.$data, {
          duration: 1,
          meeting: 25000,
          onComplete: () => {
            this.meeting = '25,000'
          }
        })
        TweenLite.to(this.$data, {
          duration: 1,
          sponsor: 1000,
          onComplete: () => {
            this.sponsor = '1,000'
          }
        })
        TweenLite.to(this.$data, {
          duration: 1,
          mechanism: 300,
          onComplete: () => {
            this.mechanism = '300'
          }
        })
      }, 500);
    },
    // 点击注册 
    handleWannaRegister() {
      let url = apiObj.VUE_APP_BASE_SERVE + 'base/register'
      window.open(url)
    },
    // 点击查看视频
    handleWannaVideo() {
      this.isvideo = true
    }
  },

  mounted() {
    this.anniStatistics()
  },
}
</script>
<style lang='less' scoped>
.header_wrap {
  width: 1200px;
  margin: 0 auto;
  .header_top_wrap {
    width: 100%;
    height: 540px;
    background: rgba(0, 102, 255, 0.04);
    position: relative;
    .header_top_left {
      width: 418px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 71px;
      .header_top_left_text {
        width: 100%;
      }
      .header_top_left_btn_wrap {
        margin-top: 90px;
        display: flex;
        justify-content: center;
        .header_top_left_btn {
          height: 44px;
          box-sizing: border-box;
          // line-height: 44px;
          line-height: 40px;
          width: 120px;
          border-radius: 2px;
          margin-right: 30px;
          text-align: center;
          background: #fff;
          color: #06f;
          font-size: 16px;
          border: 1px solid #06f;
          cursor: pointer;
          user-select: none;
          &:last-child {
            margin-right: 0px;
          }
        }
        .primary_btn {
          background: #06f;
          color: #fff;
        }
      }
    }
    .header_top_right {
      width: 508px;
      height: 414px;
      position: absolute;
      top: 50%;
      right: 66px;
      transform: translateY(-50%);
    }
  }
  .header_static_wrap {
    padding: 70px 0;
    .header_static_body {
      display: flex;
      justify-content: space-between;
      .header_static_item {
        // flex: 1;
        display: flex;
        user-select: none;
        .static_item_img_wrap {
          font-size: 0px;
          .static_item_img {
            width: 70px;
          }
        }
        .static_item_info {
          flex: 1;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .static_item_number {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #06f;
          }
          .static_item_text {
            margin-top: 7px;
            font-size: 16px;
            line-height: 24px;
            color: #999;
          }
        }
      }
    }
  }
}
</style>