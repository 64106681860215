<!-- 视频播放 -->
<template>
  <div
    class='cus_video_wrap'
    v-show="isvideo"
  >
    <div class="cus_video_inner">
      <video-player
        ref="videoplayer"
        class="case_video_box"
        :options="playerOptions"
        :playsinline="true"
      ></video-player>
      <a-icon
        type="plus"
        class="cus_video_close"
        @click="handleCloseVideo"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Icon,
} from 'ant-design-vue'

Vue.use(Icon)
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  name: 'CusVideo',
  props: {
    isvideo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videoUrl: 'https://cdn.banhuiyun.com/system/video/bhj_official_v1.0.mp4',
      playerOptions: {
        preload: 'auto',
        aspectRatio: '16:9',
        fluid: true,
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: 'https://cdn.banhuiyun.com/system/video/bhj_official_v1.0.mp4',
          }
        ],
      }
    };
  },

  watch: {
    isvideo(val) {
      if (val) {
        this.$refs.videoplayer.player.play()
      } else {
        this.$refs.videoplayer.player.src(this.videoUrl)
      }
    }
  },

  components: {
    videoPlayer
  },

  methods: {
    // 关闭视频
    handleCloseVideo() {
      this.$emit('update:isvideo', false)
    }
  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.cus_video_wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  .cus_video_inner {
    position: relative;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .cus_video_close {
      position: absolute;
      top: 0;
      right: -40px;
      font-size: 34px;
      z-index: 1001;
      color: #fff;
      transform: rotateZ(45deg);
      transition: all ease-in 0.3s;
      cursor: pointer;
      &:hover {
        transform: rotateZ(135deg) scale(1.2);
        color: #06f;
      }
    }
  }
}
</style>