<!-- 著作权 -->
<template>
  <div class="Copyright_wrap">
    <div class="Copyright_content_wrap">
      <img
        :src="IMG_LOGO_SUPPORT"
        alt="Copyright_img"
        class="Copyright_content_img"
      />
      <span class="Copyright_content_text">技术支持</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PCopyright',
  data() {
    return {
      IMG_LOGO_SUPPORT: require('./logo.png'),
    }
  },

  components: {},

  methods: {},

  mounted() { },
}
</script>
<style lang="less" scoped>
.Copyright_wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // background: #f7f8fa;
  height: 40px;
  text-align: center;
  .Copyright_content_wrap {
    overflow: hidden;
    display: inline-block;
    margin-top: 12px;
    img.Copyright_content_img {
      height: 14px;
      float: left;
    }
    .Copyright_content_text {
      float: left;
      color: #c5c8ce;
      font-size: 14px;
      margin-left: 6px;
    }
  }
}
</style>
