<!-- 下载按钮 -->
<template>
  <div
    class='download_btn_wrap'
    :class="isSolid ? '' : 'solid_sty' "
    @click="handleDownload"
  >
    <a-icon
      class="download_icon"
      theme="filled"
      :type="icon"
    />
    <span class="btn_text">{{text}}</span>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Icon,
} from 'ant-design-vue'
Vue.use(Icon)
export default {
  name: 'DownloadBtn',
  props: {
    icon: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    isSolid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    };
  },

  components: {},

  methods: {
    handleDownload() {
      this.$emit('listenHandleClick')
    }
  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.download_btn_wrap {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: center;
  min-width: 175px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid #06f;
  background: #06f;
  cursor: pointer;
  user-select: none;
  .download_icon {
    font-size: 20px;
    margin-right: 15px;
    color: #fff;
  }
  .btn_text {
    font-size: 16px;
    color: #fff;
  }
}
.solid_sty {
  background: #fff;
  .download_icon,
  .btn_text {
    color: #06f;
  }
}
</style>