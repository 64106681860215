<!-- 案例首页 -->
<template>
  <div class='cases_home_wrap'>
    <div class="cases_home_body">
      <!-- 标题 -->
      <!-- <Title
        :title="'合作案例'"
        :desc="'助力众多会议成功举办'"
      /> -->
      <div
        class="header_wrap"
        data-aos="fade-left"
      >
        <div class="header_title">合作案例</div>
        <div class="header_desc">助力众多会议成功举办</div>
        <div class="header_search_warp">
          <a-input-search
            placeholder="请输入案例名称"
            size="large"
            v-model="search_val"
            class="header_search"
            @search="onSearch"
          >
            <a-button
              icon="search"
              slot="enterButton"
              type="primary"
              class="header_search_btn"
            >
              搜索
            </a-button>
          </a-input-search>
        </div>
      </div>
      <!-- 案例内容 -->
      <div class="cases_home_content">
        <!-- data-aos="fade-up" -->
        <!-- 数据分类 -->
        <div class="cases_nav_wrap">
          <div class="cases_nav_item_wrap">
            <div class="cases_nav_item industry_nav">
              <span class="cases_nav_text">行业</span>
              <a-icon
                class="cases_nav_icon"
                type="down"
              />
              <!-- 行业分类待选项 -->
              <div
                ref="industrypick"
                class="nav_drop_down_wrap industry_drop"
              >
                <div class="drop_down_body">
                  <div
                    class="drop_down_item_warp"
                    v-for="item of industryarr"
                    :key="item.id"
                    @click="handlePickLabel('industry',item.id)"
                  ><span
                      class="drop_down_item"
                      :class="item.active ? 'drop_down_active_item' : ''"
                    >{{item.name}}</span></div>
                </div>

              </div>
            </div>
            <div class="cases_nav_item scenes_nav">
              <span class="cases_nav_text">场景</span>
              <a-icon
                class="cases_nav_icon"
                type="down"
              />
              <!-- 场景分类待选项 -->
              <div
                ref="scenepick"
                class="nav_drop_down_wrap scenes_drop"
              >
                <div class="drop_down_body">
                  <div
                    class="drop_down_item_warp"
                    v-for="item of scenearr"
                    :key="item.id"
                    @click="handlePickLabel('scene',item.id)"
                  ><span
                      class="drop_down_item"
                      :class="item.active ? 'drop_down_active_item' : ''"
                    >{{item.name}}</span></div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- 案例列表 -->
        <div class="cases_list_wrap">
          <div
            class="cases_list_item"
            v-for="item of caselist"
            :key="item.uid"
            data-aos="zoom-in"
            @click="handleCaseItem(item.uid)"
          >
            <div
              class="case_item_img_wrap"
              v-lazy:background-image="`${apiObj.VUE_APP_BASE_CDN + item.cover}!/fw/640`"
            >
              <!-- <img v-lazy="item.img"> -->
            </div>
            <div class="case_item_info_wrap">
              <div class="case_item_title">{{item.title}}</div>
              <div class="case_item_desc">{{item.introduction}}</div>
              <div class="case_item_footer">
                <div class="case_item_logo_warp">
                  <img v-lazy="`${apiObj.VUE_APP_BASE_CDN + item.logo}`">
                </div>
                <div class="case_item_detail_btn">查看详情</div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- 加载更多 -->
    <infinite-loading
      @infinite="infiniteHandler"
      :identifier="infiniteId"
    >
      <span
        slot="no-more"
        style="fontSize: 14px;marginBottom:20px;display:block;marginTop:50px;"
      > - 无更多了 - </span>
      <span
        slot="no-results"
        style="fontSize: 14px;marginBottom:20px;display:block;marginTop:50px;"
      > - 无更多了 - </span>
    </infinite-loading>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Icon,
  Input
} from 'ant-design-vue'
Vue.use(Icon)
Vue.use(Input)

// import Title from '@c/Title'
import InfiniteLoading from 'vue-infinite-loading'

import { GetCaseList } from '@a/js/api/official'
import apiObj from '@a/js/api'
export default {
  name: 'CasesHome',
  data() {
    return {
      apiObj,
      loading: false,
      page: 0,
      page_size: 12,
      maxpage: 1,
      infiniteId: +new Date(),
      search_val: '',
      industry: '',
      scene: '',
      activeType: '',
      caselist: [],
      industryarr: [
        {
          id: 'in01',
          name: '地产',
          active: false
        },
        {
          id: 'in02',
          name: '汽车',
          active: false
        },
        {
          id: 'in03',
          name: 'IT',
          active: false
        },
        {
          id: 'in04',
          name: '金融',
          active: false
        },
        {
          id: 'in05',
          name: '文娱',
          active: false
        },
        {
          id: 'in06',
          name: '批发零售',
          active: false
        },
        {
          id: 'in07',
          name: '消费品',
          active: false
        },
        {
          id: 'in08',
          name: '交通运输',
          active: false
        },
        {
          id: 'in09',
          name: '教育培训',
          active: false
        },
        {
          id: 'in10',
          name: '生活服务',
          active: false
        },
        {
          id: 'in11',
          name: '商业服务',
          active: false
        },
        {
          id: 'in12',
          name: '政府',
          active: false
        },
        {
          id: 'in13',
          name: '公共服务',
          active: false
        },

      ],
      scenearr: [
        {
          id: 'sc01',
          name: '会议类',
          active: false
        },
        {
          id: 'sc02',
          name: '庆典仪式',
          active: false
        },
        {
          id: 'sc03',
          name: '晚宴晚会',
          active: false
        },
        {
          id: 'sc04',
          name: '市集嘉年华',
          active: false
        },
        {
          id: 'sc05',
          name: '运动赛事',
          active: false
        },
        {
          id: 'sc06',
          name: '推广活动',
          active: false
        },
        {
          id: 'sc07',
          name: '内部活动',
          active: false
        },
        {
          id: 'sc08',
          name: '展览展会',
          active: false
        },
        {
          id: 'sc09',
          name: '旅游会奖',
          active: false
        },
        {
          id: 'sc10',
          name: '政务会议',
          active: false
        },
      ],
    };
  },

  watch: {
    industry() {
      this.wannaReloadList()
    },
    scene() {
      this.wannaReloadList()
    }
  },

  components: {
    // Title,
    InfiniteLoading
  },

  methods: {
    // 取消选择
    leaveNavItem() {
      this.activeType = ''
    },
    // 开始选择分类
    hoverNavItem(type) {
      this.activeType = type
    },
    // 点击查看案例
    handleCaseItem(id) {
      this.$router.push({
        name: 'CasesIntro',
        params: {
          caseid: id
        }
      })
    },
    // 选择分类项
    handlePickLabel(field, id) {
      console.log(field, id)
      let str = `${field}arr`
      // console.log(str)
      this[str].map(item => {
        if (item.id === id) {
          if (!item.active) {
            this[field] = item.name
            console.log(item.name)
            item.active = !item.active
          } else {
            this[field] = ''
            item.active = false
          }
        } else {
          item.active = false
        }
      })

      let refstr = `${field}pick`
      // this.$refs[refstr].style.visibility = 'hidden'
      console.log(this.$refs[refstr].style.visibility)
    },
    // 开始搜索
    onSearch() {
      this.wannaReloadList()
    },
    infiniteHandler($state) {
      // console.log('load more ')
      if (this.page < this.maxpage) {
        this.page++
        this.wannaCasesList($state)
      } else {
        // console.log('complete')
        $state.complete()
      }
    },
    // 重新请求数据
    wannaReloadList() {
      this.page = 0
      this.maxpage = 1
      this.caselist = []
      this.infiniteId += 1
    },
    // 获取案例列表
    wannaCasesList(state) {
      let data = {
        page: this.page,
        page_size: this.page_size,
        is_show_official_home: 0,
        search_value: this.search_val,
        industry: this.industry,
        scene: this.scene
      }
      this.loading = true
      GetCaseList(data).then(res => {
        // console.log(res)
        this.caselist = [...this.caselist, ...res.data.list]
        this.maxpage = Math.ceil(res.data.count / this.page_size)
        this.loading = false
        state.loaded()

      }).catch(() => {
        this.loading = false
        state.loaded()
      })
    }

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.cases_home_wrap {
  width: 100%;
  padding-bottom: 100px;
  .cases_home_body {
    width: 1200px;
    margin: 0 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    // 标题
    .header_wrap {
      padding: 135px 0 30px;
      .header_title {
        font-size: 44px;
        line-height: 66px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #000;
        font-weight: bold;
      }
      .header_desc {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.1em;
        text-align: center;
        color: #000;
      }
      .header_search_warp {
        margin-top: 30px;
        text-align: center;
        .header_search {
          width: 360px;
          /deep/ .ant-input-lg {
            border-radius: 2px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .header_search_btn {
            border-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            background-color: #06f;
            border-top-color: #06f;
          }
        }
      }
    }
    // 案例内容
    .cases_home_content {
      padding: 0 100px;
      min-height: 300px;
      overflow: hidden;
      // 数据分类
      .cases_nav_wrap {
        height: 70px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        position: relative;
        .cases_nav_item_wrap {
          display: inline-block;
          margin: 0 auto;
          .cases_nav_item {
            float: left;
            margin: 0 20px;
            padding: 0 10px;
            cursor: pointer;
            user-select: none;
            height: 70px;
            display: flex;
            align-items: center;
            .cases_nav_text {
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1em;
              color: #000;
            }
            .cases_nav_icon {
              margin-left: 5px;
              font-size: 14px;
              color: #000;
              transition: all 0.3s ease;
            }
            &:hover {
              .cases_nav_text,
              .cases_nav_icon {
                color: #06f;
              }
              .cases_nav_icon {
                transform: rotateZ(-180deg);
              }
            }
            .nav_drop_down_wrap {
              position: absolute;
              top: 85px;
              width: 1000px;
              left: 50%;
              transform: translateX(-50%);
              background: #fff;
              z-index: 999;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              border-radius: 2px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease;
              cursor: default;
              .drop_down_body {
                padding: 20px 95px;
                .drop_down_item_warp {
                  width: 20%;
                  display: inline-block;
                  margin: 0 15px;
                  text-align: center;
                  .drop_down_item {
                    display: inline-block;
                    box-sizing: border-box;
                    padding: 0 15px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    height: 50px;
                    line-height: 50px;
                    &:hover {
                      // color: #06f;
                      transform: translateY(-2px);
                    }
                  }
                  .drop_down_active_item {
                    color: #06f;
                  }
                }
              }
            }
          }
          .industry_nav {
            &:hover {
              .industry_drop {
                top: 68px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .scenes_nav {
            &:hover {
              .scenes_drop {
                top: 68px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .funcs_nav {
            &:hover {
              .funcs_drop {
                top: 68px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }
      }
      // 案例列表
      .cases_list_wrap {
        padding-top: 20px;
        // overflow-x: hidden;
        .cases_list_item {
          font-size: 0;
          margin-top: 20px;
          margin-bottom: 20px;
          width: 306px;
          display: inline-block;
          border-radius: 2px;
          box-sizing: border-box;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          background: #fff;
          cursor: pointer;
          transition: all 0.3s ease;
          overflow: hidden;
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0px 2px 8px rgba(0, 102, 225, 0.1);
          }
          &:nth-child(3n-1) {
            margin-left: 40px;
            margin-right: 40px;
          }
          .case_item_img_wrap {
            height: 170px;
            background-size: cover;
            background-repeat: no-repeat;
            // img {
            //   width: 100%;
            //   height: 100%;
            // }
          }
          .case_item_info_wrap {
            padding: 20px;
            .case_item_title {
              font-size: 16px;
              line-height: 24px;
              height: 48px;
              font-weight: bold;
              text-align: justify;
              color: #000;
              margin-bottom: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }
            .case_item_desc {
              font-size: 14px;
              line-height: 20px;
              height: 60px;
              text-align: justify;
              color: #999;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .case_item_footer {
              margin-top: 20px;
              display: flex;
              .case_item_logo_warp {
                height: 24px;
                flex: 1;
                img {
                  height: 100%;
                }
              }
              .case_item_detail_btn {
                color: #06f;
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
</style>