<!-- 素材首页 -->
<template>
  <div class='material_home_wrap'>
    <div class="mate_body_wrap">
      <!-- 页面标题 -->
      <Title
        :title="'设计素材'"
        :desc="'欢迎合作'"
      />
      <div class="mate_body">
        <!-- 素材列表 -->
        <div class="mate_list_wrap">
          <div
            class="mate_list_item"
            v-for="(item,index) of matelist"
            :key="index"
            data-aos="zoom-in"
          >
            <div class="mate_item_wrap">
              <div
                class="mate_item_preview"
                :style="`backgroundColor:${item.bgcolor}`"
                v-lazy:background-image="`${apiObj.VUE_APP_BASE_CDN + item.cover + item.size}`"
              ></div>
              <div class="mate_item_btn_wrap">
                <div
                  class="mate_item_btn"
                  v-for="(btnitem,index) of item.download"
                  :key="index"
                  :class="index == 1 ? 'divide_item' : ''"
                  @click="handleDownMaterial(btnitem)"
                >{{btnitem.text}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@c/Title'

import apiObj from '@a/js/api.js'
import { DownloadAuth } from '@a/js/api/public'
export default {
  name: 'MaterialHome',
  data() {
    return {
      apiObj,
      matelist: [
        {
          name: 'logo_蓝色',
          cover: '/system/image/official/media/Logo_blue.png',
          size: '!/fw/220',
          bgcolor: '#fff',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_blue.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/Logo_blue.png'
            },
          ]
        },
        {
          name: 'logo_蓝色_企业级办会管理系统',
          cover: '/system/image/official/media/Logo_blue_slogan.png',
          size: '!/fw/220',
          bgcolor: '#fff',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_blue_slogan.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/Logo_blue_slogan.png'
            },
          ]
        },
        {
          name: 'logo_蓝色_办会就用办会家',
          cover: '/system/image/official/media/logo_blue_slogan_v2.png',
          size: '!/fw/220',
          bgcolor: '#fff',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_blue_slogan_v2.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/logo_blue_slogan_v2.png'
            },
          ]
        },
        {
          name: 'logo_白色',
          cover: '/system/image/official/media/logo_white.png',
          size: '!/fw/220',
          bgcolor: '#06f',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_white.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/logo_white.png'
            },
          ]
        },
        {
          name: 'logo_白色_企业级办会管理系统',
          cover: '/system/image/official/media/logo_white_slogan.png',
          size: '!/fw/220',
          bgcolor: '#06f',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_white_slogan.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/logo_white_slogan.png'
            },
          ]
        },
        {
          name: 'logo_白色_办会就用办会家',
          cover: '/system/image/official/media/logo_white_slogan_v2.png',
          size: '!/fw/220',
          bgcolor: '#06f',
          download: [
            {
              text: 'SVG',
              url: '/system/image/official/media/logo_white_slogan_v2.svg'
            },
            {
              text: 'PNG',
              url: '/system/image/official/media/logo_white_slogan_v2.png'
            },
          ]
        },

        {
          name: '微信二维码',
          cover: '/system/image/official/media/bhj_wx_qrcode_big.jpeg',
          size: '!/fh/180',
          bgcolor: '#fff',
          download: [
            {
              text: '8cm',
              url: '/system/image/official/media/bhj_wx_qrcode_small.jpeg'
            },
            {
              text: '50cm',
              url: '/system/image/official/media/bhj_wx_qrcode_big.jpeg'
            },
          ]
        },
        {
          name: 'logo_正方形_蓝色',
          cover: '/system/image/official/media/logo_square_blue.png',
          size: '!/fh/160',
          bgcolor: '#fff',
          download: [
            {
              text: 'PNG',
              url: '/system/image/official/media/logo_square_blue.png'
            },
          ]
        },
        {
          name: 'logo_ico',
          cover: '/system/image/official/media/logo.ico.png',
          size: '!/fh/160',
          bgcolor: '#fff',
          download: [
            {
              text: 'PNG',
              url: '/system/image/official/media/logo.ico.png'
            },
          ]
        },

      ]
    };
  },

  components: {
    Title
  },

  methods: {
    // 点击下载素材
    handleDownMaterial(info) {
      let data = {
        filePath: info.url
      }
      DownloadAuth(data).then(res => {
        var xhr = new XMLHttpRequest()
        xhr.open("GET", apiObj.VUE_APP_UPYUN_API + '/bhy-assets' + info.url, true)
        xhr.setRequestHeader("Authorization", res.data.authorization)
        xhr.responseType = 'blob'
        xhr.onreadystatechange = () => {
          var XMLHttpReq = xhr
          if (XMLHttpReq.readyState == 4 && XMLHttpReq.status == 200) {
            let data = XMLHttpReq.response
            let imgs = info.url.split('/')
            let imgname = imgs[imgs.length - 1]
            this.downloadFile(data, imgname)
          }
        }
        xhr.send()
      })
    },
    downloadSvg(str, name) {
      var blob = new Blob([str], { type: 'image/svg+xml' });
      var a = document.createElement('a');
      a.download = name;
      a.href = URL.createObjectURL(blob);
      a.click();
    },
    downloadFile(blob, name) {
      var reader = new FileReader()
      reader.onload = (e) => {
        // console.log(e.currentTarget.result)
        let base64 = e.currentTarget.result
        var a = document.createElement('a')
        a.download = name
        a.href = base64
        a.click();
      }
      reader.readAsDataURL(blob)



    }
  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.material_home_wrap {
  width: 100%;
  padding-bottom: 100px;
  .mate_body_wrap {
    width: 1200px;
    margin: 0 auto;
  }
  .mate_body {
    padding: 0 100px;
  }
  .mate_list_wrap {
    padding-top: 20px;
    .mate_list_item {
      font-size: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 306px;
      display: inline-block;
      border-radius: 2px;
      box-sizing: border-box;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      background: #fff;
      overflow: hidden;
      &:nth-child(3n-1) {
        margin-left: 40px;
        margin-right: 40px;
      }
      .mate_item_wrap {
        width: 100%;
        height: 100%;
        .mate_item_preview {
          height: 240px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
        }
        .mate_item_btn_wrap {
          display: flex;
          position: relative;
          .mate_item_btn {
            height: 80px;
            line-height: 80px;
            text-align: center;
            flex: 1;
            font-size: 18px;
            letter-spacing: 1px;
            color: #000;
            user-select: none;
            cursor: pointer;
            border-top: 1px solid #eee;
            box-sizing: border-box;
            &:hover {
              background: #06f;
              color: #fff;
            }
          }
          .divide_item {
            position: relative;
            &::before {
              content: "";
              height: 40px;
              width: 0.1px;
              background: #eee;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            &:hover {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>