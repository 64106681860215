<!-- 移动端导航页面 -->
<template>
  <div class='mob_guide_wwap'>
    <img
      v-lazy="apiObj.VUE_APP_BASE_CDN + guide"
      class="mob_guide_img"
    >
  </div>
</template>

<script>
import apiObj from '@a/js/api'
export default {
  name: 'MobileGuide',
  data() {
    return {
      apiObj,
      guide: "/system/image/official/other/show_mini_program.png"
    };
  },

  components: {},

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.mob_guide_wwap {
  font-size: 0;
  width: 100vw;
  height: 100vh;
  .mob_guide_img {
    width: 100%;
  }
}
</style>