<!-- 应用中心首页 -->
<template>
  <div class='apps_home_wrap'>
    <!-- 页面标题 -->
    <Title
      :title="'获取应用'"
      :desc="'请根据需求，选择适合您的应用'"
    />
    <!-- 小程序签到 -->
    <WeappCheck />
    <!-- PDA 签到 -->
    <PdaCheck />
    <!-- 桌面签到 -->
    <PcCheck />
    <!-- AI 智能签到 -->
    <AiCheck />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Button,
} from 'ant-design-vue'
Vue.use(Button)

import Title from '@c/Title'
import WeappCheck from './components/weappCheck'
import PdaCheck from './components/pdaCheck'
import PcCheck from './components/pcCheck'
import AiCheck from './components/aiCheck'
export default {
  name: 'Applications',
  data() {
    return {

    };
  },

  components: {
    Title,
    WeappCheck,
    PdaCheck,
    PcCheck,
    AiCheck
  },

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.apps_home_wrap {
  width: 100%;
}
</style>