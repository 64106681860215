<!-- AI 智能签到 -->
<template>
  <AppCard
    :introImg="introImg"
    :animate="'fade-left'"
    :title="'AI 智能签到'"
    :introInfo="'适用于嘉宾身份有严格验证功能需求的场景。通过注册收集人脸信息，并在签到环节验证人脸信息完成签到。'"
  >
    <template #download>
      <div class="pda_dowmload_wrap">
        <!-- <div
          class="code_img"
          :style="{backgroundImage: 'url('+qrcode+')'}"
        ></div> -->
        <vueQr
          class="link_code"
          :text="link"
          :size="120"
          :margin="0"
        />
        <div class="pda_download_btn_wrap">
          <DownloadBtn
            :icon="'android'"
            :text="'Android 下载'"
            @listenHandleClick="hanldeWannaDownload"
          />
        </div>
      </div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from './appcard'
import DownloadBtn from './downloadBtn'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
import vueQr from 'vue-qr'
export default {
  name: 'AiCheck',
  data() {
    return {
      introImg: apiObj.VUE_APP_BASE_CDN + Assets.ai_smart_check,
      qrcode: require('@a/img/apps/qrcode.png'),
      orglink: '/apps/check_face/android/check_face.apk',
      link: ''
    };
  },

  components: {
    AppCard,
    DownloadBtn,
    vueQr
  },

  methods: {
    // 初始化链接
    initDownloadLink() {
      let str = apiObj.VUE_APP_BASE_CDN + this.orglink + '?v=' + new Date().getTime()
      this.link = str
    },
    // 点击下载
    hanldeWannaDownload() {
      window.location.href = this.link
    }
  },

  mounted() {
    this.initDownloadLink()
  },
}
</script>
<style lang='less' scoped>
.pda_dowmload_wrap {
  height: 120px;
  display: flex;
  .code_img {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin-right: 75px;
  }
  .link_code {
    margin-right: 75px;
  }
  .pda_download_btn_wrap {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
</style>