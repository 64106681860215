<!-- 小程序签到 -->
<template>
  <AppCard
    :color="true"
    :animate="'fade-right'"
    :introImg="introImg"
    :title="'移动签到 - 微信小程序版'"
    :introInfo="'适用于小规模会议或分会场的场景。支持：扫码、姓名、手机四位、邮箱等验证方式。微信扫描下方二维码，即刻使用。'"
  >
    <template #download>
      <div
        class="weapp_code"
        :style="{backgroundImage: 'url('+codeImg+')'}"
      ></div>
    </template>
  </AppCard>
</template>

<script>
import AppCard from './appcard'
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'WeappCheck',
  data() {
    return {
      introImg: apiObj.VUE_APP_BASE_CDN + Assets.minprogram,
      codeImg: apiObj.VUE_APP_BASE_CDN + Assets.qr_miniprogram,
    };
  },

  components: {
    AppCard
  },

  methods: {

  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.weapp_code {
  height: 180px;
  width: 180px;
  background-repeat: no-repeat;
  background-size: 100%;
}
</style>