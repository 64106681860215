import request from '@a/js/request'

// 获取案例列表
export function GetCaseList(query) {
  return request({
    url: '/official/v1/cases/list',
    method: 'get',
    params: query,
  })
}

// 获取案例详情
export function GetCaseInfo(query) {
  return request({
    url: '/official/v1/cases',
    method: 'get',
    params: query,
  })
}