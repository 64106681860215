<!-- 应用列表 -->
<template>
  <div class='app_wrap'>
    <div
      class="app_body"
      data-aos="fade-up"
    >
      <div class="app_title">{{title}}</div>
      <div class="app_content">
        <div
          class="app_item_wrap"
          v-for="item of applist"
          :key="item.id"
        >
          <div class="app_item">
            <div class="app_img_wrap">
              <div
                class="app_img"
                v-lazy:background-image="item.img"
              ></div>
            </div>
            <div class="app_name">{{item.name}}</div>
          </div>
        </div>
      </div>
      <!-- 202104300928 暂时隐藏产品中心导航按钮 -->
      <div
        v-show="false"
        class="app_more_wrap"
      >
        <a-button
          @click="handleMoreApp"
          type="primary"
        >
          查看更多
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import apiObj from '@a/js/api'
import Assets from '@a/js/assets'
export default {
  name: 'Application',
  data() {
    return {
      title: "覆盖各类场景、全流程的系统功能",
      applist: [
        {
          id: 'app01',
          name: '官方网站',
          img: apiObj.VUE_APP_BASE_CDN + Assets.website,
        },
        {
          id: 'app02',
          name: '注册报名',
          img: apiObj.VUE_APP_BASE_CDN + Assets.register,
        },
        {
          id: 'app03',
          name: '在线售票',
          img: apiObj.VUE_APP_BASE_CDN + Assets.sell_ticket,
        },
        {
          id: 'app04',
          name: '会务通知',
          img: apiObj.VUE_APP_BASE_CDN + Assets.message,
        },
        {
          id: 'app05',
          name: '签到核验',
          img: apiObj.VUE_APP_BASE_CDN + Assets.check,
        },
        {
          id: 'app06',
          name: '嘉宾接送',
          img: apiObj.VUE_APP_BASE_CDN + Assets.msgvip,
        },
        {
          id: 'app7',
          name: '图片直播',
          img: apiObj.VUE_APP_BASE_CDN + Assets.photos,
        },
        {
          id: 'app08',
          name: '现场互动',
          img: apiObj.VUE_APP_BASE_CDN + Assets.hudong,
        },
        {
          id: 'app09',
          name: '在线订房',
          img: apiObj.VUE_APP_BASE_CDN + Assets.booking,
        },
        {
          id: 'app10',
          name: '入住分房',
          img: apiObj.VUE_APP_BASE_CDN + Assets.diy_logo,
        },
        {
          id: 'app11',
          name: '集团权限',
          img: apiObj.VUE_APP_BASE_CDN + Assets.group_permission,
        },
        {
          id: 'app12',
          name: '电子签约',
          img: apiObj.VUE_APP_BASE_CDN + Assets.msgcontract,
        },
        {
          id: 'app13',
          name: '供应商管理',
          img: apiObj.VUE_APP_BASE_CDN + Assets.supplier_management,
        },
        {
          id: 'app14',
          name: '数字资产管理',
          img: apiObj.VUE_APP_BASE_CDN + Assets.digital_property,
        },
        {
          id: 'app15',
          name: 'CRM 客户转化',
          img: apiObj.VUE_APP_BASE_CDN + Assets.crm,
        },
        {
          id: 'app16',
          name: '视频直播',
          img: apiObj.VUE_APP_BASE_CDN + Assets.live,
        },
      ]
    };
  },

  components: {},

  methods: {
    // 点击查看更多应用
    handleMoreApp() {
      this.$router.push({
        name: 'ProductsHome'
      })
    }
  },

  mounted() {

  },
}
</script>
<style lang='less' scoped>
.app_wrap {
  width: 100%;
  // background: #f7fcff;
  .app_body {
    width: 1200px;
    margin: 0 auto;
    // padding: 100px 0;
    padding: 0;
    .app_title {
      font-size: 44px;
      font-weight: bold;
      line-height: 66px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #000;
      user-select: none;
    }
    .app_content {
      margin-top: 70px;
      .app_item_wrap {
        width: 25%;
        display: inline-block;
        box-sizing: border-box;
        margin-bottom: 30px;
        padding: 0 15px;
        cursor: pointer;
        transition: all 0.3s;
        .app_item {
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          .app_img_wrap {
            height: 160px;
            background: #fff;
            padding: 10px;
            border-radius: 2px 2px 0 0;
            .app_img {
              height: 100%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              border-radius: 2px;
            }
            .app_img_border {
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              border: 10px solid #fff;
            }
          }
          .app_name {
            height: 40px;
            line-height: 35px;
            text-align: center;
            color: #000;
            font-size: 16px;
            letter-spacing: 0.05em;
            border-radius: 0 0 2px 2px;
            background: #fff;
          }
        }
        &:hover {
          transform: translateY(-4px);
          .app_name {
            color: #06f;
            box-shadow: 0px 2px 8px rgba(0, 102, 225, 0.1);
          }
        }
      }
    }
    .app_more_wrap {
      padding-top: 30px;
      text-align: center;
    }
  }
}
</style>