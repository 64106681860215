<template>
  <div class="_404 page">
    <img
      class="_404_img"
      :src="imgError"
    />
    <div class="page-bd">
      <p class="_404-label">您访问了一个不存在的页面</p>
      <p class="_404-label-secondary">请核实您的访问地址</p>
    </div>
    <!-- 版权 -->
    <PCopyright v-show="false"></PCopyright>
  </div>
</template>

<script>
import PCopyright from "./PCopyright";
export default {
  components: {
    PCopyright
  },
  data() {
    return {
      imgError: require("./404.png")
    };
  },
  mounted() { },
  methods: {}
};
</script>

<style lang="less" scoped>
._404 {
  width: 100vw;
  height: 100vh;
  background: #fff;
  text-align: center;
  font-size: 0;

  ._404_img {
    max-height: 100vh;
    max-width: 100vw;
  }

  .page-bd {
    margin-top: -90px;
  }

  ._404-label {
    font-size: 18px;
    font-weight: 700;
    color: #78909c;
    text-align: center;
    letter-spacing: 1px;
  }

  ._404-label-secondary {
    font-size: 16px;
    color: #78909c;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 15px;
  }
}
</style>
