<!-- 价格首页 -->
<template>
  <div class='price_home_wrap'>
    <div class="price_body">
      <!-- 标题 -->
      <Title
        :title="'价格方案'"
        :desc="'高性价比、灵活的价格'"
      />
      <!-- 版本描述 -->
      <div
        class="version_intro_wrap"
        data-aos="flip-left"
      >
        <div class="intro_item_wrap">
          <div class="intro_item_name">套餐</div>
          <div class="intro_item_text text_sty">建议场景</div>
        </div>
        <div
          class="intro_item_wrap"
          v-for="item of plans"
          :key="item.id"
        >
          <div class="intro_item_name">{{item.name}}</div>
          <div class="intro_item_text">{{item.intro}}</div>
          <a-button
            @click="handleClickRegister"
            class="intro_item_btn"
          >立即试用</a-button>
        </div>
      </div>

      <!-- 价格方案 -->
      <div
        class="price_content"
        ref="pricebody"
        data-aos="fade-up"
      >
        <div
          :class="headerFix ? 'price_header_wrap_fix' : ''"
          class="price_header_wrap"
        >
          <div class="price_header_item">功能</div>
          <div
            class="price_header_item"
            v-for="item of plans"
            :key="item.id"
          >{{item.name}}</div>
        </div>
        <div
          v-show="headerFix"
          class="price_header_seat_warp"
        ></div>
        <div class="price_info_wrap">
          <div
            class="price_info_item_wrap"
            v-for="(item,index) of func"
            :key="item.name_en"
            :class="index%2 === 0 ? 'color_sty' : ''"
            data-aos="flip-up"
            data-aos-duration="1000"
          >
            <div class="price_info_item func_name">{{item.name}}</div>
            <div
              class="price_info_item"
              v-for="inner of plans"
              :key="inner.id"
            >
              <a-icon
                :class="inner.func.indexOf(item.name_en) >= 0 ? 'icon_color' : 'icon_nohave'"
                :type="inner.func.indexOf(item.name_en) >= 0 ? 'check' : 'minus'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@c/Title'
import Vue from 'vue'
import {
  Icon,
  Button
} from 'ant-design-vue'
Vue.use(Icon)
Vue.use(Button)

import apiObj from '@a/js/api'
export default {
  name: 'PriceHome',
  data() {
    return {
      headerFix: false,
      func: [
        {
          "name": "微官网",
          "name_en": "website"
        },
        {
          "name": "小程序官网",
          "name_en": "website_miniprogram"
        },
        {
          "name": "PC 网站",
          "name_en": "website_pc"
        },
        {
          "name": "注册报名",
          "name_en": "form"
        },
        {
          "name": "逻辑表单",
          "name_en": "form_logic"
        },
        {
          "name": "人脸照片核验",
          "name_en": "form_face_verify"
        },
        {
          "name": "渠道管理",
          "name_en": "form_channel"
        },
        {
          "name": "邀请码",
          "name_en": "invite_code"
        },
        {
          "name": "个人信息",
          "name_en": "form_center"
        },
        {
          "name": "多语言网站",
          "name_en": "foreign_language"
        },
        {
          "name": "在线售票",
          "name_en": "ticket"
        },
        {
          "name": "优惠码",
          "name_en": "coupon"
        },
        {
          "name": "分销",
          "name_en": "distribute"
        },
        {
          "name": "在线订餐",
          "name_en": "meal"
        },
        {
          "name": "在线订房",
          "name_en": "booking"
        },
        {
          "name": "发票管理",
          "name_en": "invoice"
        },
        {
          "name": "对账提现",
          "name_en": "withdraw"
        },
        {
          "name": "境内短信推送",
          "name_en": "message_sms_mainland"
        },
        {
          "name": "境外短信推送",
          "name_en": "message_sms_overseas"
        },
        {
          "name": "邮件推送",
          "name_en": "message_email"
        },
        {
          "name": "微信订阅消息",
          "name_en": "message_wexin_subscribe"
        },
        {
          "name": "微信签到",
          "name_en": "check_weixin"
        },
        {
          "name": "移动签到",
          "name_en": "check_mobile"
        },
        {
          "name": "桌面签到",
          "name_en": "check_desktop"
        },
        {
          "name": "PDA 签到",
          "name_en": "check_pda"
        },
        {
          "name": "人脸识别签到",
          "name_en": "check_face"
        },
        {
          "name": "闸机签到",
          "name_en": "check_turnstile"
        },
        {
          "name": "无障碍闸机签到",
          "name_en": "check_free_turnstile"
        },
        {
          "name": "自定义电子票",
          "name_en": "diy_ticket"
        },
        {
          "name": "抽奖",
          "name_en": "hd_award"
        },
        {
          "name": "数字签约",
          "name_en": "hd_contract"
        },
        {
          "name": "数字签名墙",
          "name_en": "hd_sign"
        },
        {
          "name": "AI 速记",
          "name_en": "ai_shorthand"
        },
        {
          "name": "嘉宾接送",
          "name_en": "pickup"
        },
        {
          "name": "入住分房",
          "name_en": "checkin"
        },
        {
          "name": "更换短信签名",
          "name_en": "diy_sms_signature"
        },
        {
          "name": "更换邮件签名",
          "name_en": "diy_email_signature"
        },
        {
          "name": "更换 Logo 签名",
          "name_en": "diy_Logo"
        },
        {
          "name": "隐藏版权",
          "name_en": "hide_version"
        },
        {
          "name": "更换支付方式",
          "name_en": "change_payment"
        },
        {
          "name": "集团管理权限",
          "name_en": "group_management"
        },
        {
          "name": "含 3 个子账号",
          "name_en": "group_3_sub_accounts"
        }
      ],
      plans: [
        {
          "id": "basic",
          "name": "基础版",
          "intro": "适合人数规模不大，形式相对简单的场景",
          "func": [
            "form",
            "form_face_verify",
            "form_center",
            "ticket",
            "invoice",
            "withdraw",
            "message_sms_mainland",
            "message_sms_overseas",
            "message_email",
            "check_weixin"
          ]
        },
        {
          "id": "professonal",
          "name": "专业版",
          "intro": "适合中大型规模，对效率要求较高的场景",
          "func": [
            "website",
            "website_pc",
            "form",
            "form_logic",
            "form_face_verify",
            "form_center",
            "ticket",
            "invoice",
            "coupon",
            "withdraw",
            "message_sms_mainland",
            "message_sms_overseas",
            "message_email",
            "check_weixin",
            "check_mobile",
            "check_desktop",
            "check_pda",
            "hd_award",
            "diy_sms_signature",
            "diy_email_signature"
          ]
        },
        {
          "id": "premium",
          "name": "高级版",
          "intro": "适合各类会议规模、经常办会的场景，赋能办会全流程",
          "func": [
            "website",
            "website_pc",
            "website_miniprogram",
            "form",
            "form_logic",
            "form_face_verify",
            "form_channel",
            "invite_code",
            "form_center",
            "foreign_language",
            "ticket",
            "invoice",
            "coupon",
            "distribute",
            "meal",
            "booking",
            "withdraw",
            "message_sms_mainland",
            "message_sms_overseas",
            "message_email",
            "check_weixin",
            "check_mobile",
            "check_desktop",
            "check_pda",
            "check_face",
            "check_turnstile",
            "check_free_turnstile",
            "hd_award",
            "hd_contract",
            "hd_sign",
            "ai_shorthand",
            "diy_ticket",
            "pickup",
            "checkin",
            "diy_sms_signature",
            "diy_email_signature",
            "diy_Logo",
            "hide_version",
            "change_payment"
          ]
        },
        {
          "id": "group",
          "name": "集团版",
          "intro": "适合对不同团队、分公司和项目区分管理，多项目独立运营场景",
          "func": [
            "website",
            "website_pc",
            "website_miniprogram",
            "form",
            "form_logic",
            "form_face_verify",
            "form_channel",
            "invite_code",
            "form_center",
            "foreign_language",
            "ticket",
            "invoice",
            "coupon",
            "distribute",
            "meal",
            "booking",
            "withdraw",
            "message_sms_mainland",
            "message_sms_overseas",
            "message_email",
            "check_weixin",
            "check_mobile",
            "check_desktop",
            "check_pda",
            "check_face",
            "check_turnstile",
            "check_free_turnstile",
            "hd_award",
            "hd_contract",
            "hd_sign",
            "ai_shorthand",
            "diy_ticket",
            "pickup",
            "checkin",
            "diy_sms_signature",
            "diy_email_signature",
            "diy_Logo",
            "hide_version",
            "group_management",
            "group_3_sub_accounts",
            "change_payment"
          ]
        }
      ]
    };
  },

  components: {
    Title
  },

  methods: {
    handleScroll() {
      // console.log()
      let top = this.$refs.pricebody.getBoundingClientRect().top
      if (top <= 70) {
        this.headerFix = true
      } else {
        this.headerFix = false
      }
    },
    // 点击试用
    handleClickRegister() {
      let url = apiObj.VUE_APP_BASE_SERVE + 'base/register'
      window.open(url)
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang='less' scoped>
.price_home_wrap {
  width: 100%;
  .price_body {
    width: 1200px;
    padding: 0 100px 80px;
    margin: 0 auto;
    overflow-y: hidden;
    .version_intro_wrap {
      width: 100%;
      // min-height: 300px;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      border-top: 4px solid #06f;
      padding: 30px 0;
      display: flex;
      .intro_item_wrap {
        flex: 1;
        border-right: 1px solid #eee;
        display: flex;
        flex-direction: column;
        padding: 20px;
        &:last-child {
          border-right: 0;
        }
        .intro_item_name {
          font-size: 30px;
          font-weight: 700;
          line-height: 45px;
          color: #000;
          text-align: center;
          margin-bottom: 20px;
          letter-spacing: 14px;
        }
        .intro_item_text {
          flex: 1;
          min-height: 100px;
          padding-bottom: 20px;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 1px;
          font-weight: 400;
          color: #777;
          text-align: center;
        }
        .text_sty {
          color: #000;
        }
        .intro_item_btn {
          color: #06f;
          border-color: #06f;
          width: 100px;
          margin: 0 auto;
          border-radius: 2px;
        }
      }
    }
    .price_content {
      width: 100%;
      .price_header_wrap {
        height: 70px;
        display: flex;
        .price_header_item {
          flex: 1;
          padding: 0 10px;
          text-align: center;
          font-size: 20px;
          line-height: 70px;
          letter-spacing: 0.1em;
          color: #000;
          font-weight: bold;
          user-select: none;
        }
      }
      .price_header_wrap_fix {
        width: 1000px;
        position: fixed;
        top: 70px;
        z-index: 999;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
      }
      .price_header_seat_warp {
        width: 100%;
        height: 70px;
      }
      .price_info_wrap {
        user-select: none;
        .price_info_item_wrap {
          height: 60px;
          line-height: 60px;
          display: flex;
          border-radius: 2px;
          transition: all 0.3s ease;
          &:hover {
            transform: translateY(-2px);
            box-shadow: 0px 2px 8px rgba(0, 102, 225, 0.1);
          }
          .price_info_item {
            flex: 1;
            text-align: center;
            .icon_color {
              color: #000;
              font-size: 24px;
            }
            .icon_nohave {
              color: #ddd;
              font-size: 24px;
            }
          }
          .func_name {
            font-size: 16px;
            letter-spacing: 0.1em;
            color: #000;
          }
        }
        .color_sty {
          background: #f7fcff;
        }
      }
    }
  }
}
</style>